import React, { useEffect } from "react";
import {
  KenkoVerseSection,
  KenkoVerseTitle,
} from "./KenkoVerseSection/KenkoVerseSection";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { backendDrivenEvents } from "../../slices/onboarding.slice";
import { backendEvents } from "../../shared/constants/backend-events";
import { KenkoVerseCard } from "./KenkoVerseSection/KenkoVerseCard";

export const KenkoVerse = () => {
  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_KENKO_VERSE_SECTION,
        })
      );
    }
  }, [inView, dispatch]);

  const kenkoVerseArr = [
    {
      image: "rohit.png",
      title: "During an emergency, help arrived in 15 mins!",
      name: "Rohit Gupta",
      ytIcon: "yt_button.png",
      link: "https://youtu.be/T7A6u7APGvc?feature=shared",
    },
    {
      image: "shruti.png",
      title: "Kenko saved me ₹30000 on Ultrasound tests!",
      name: "Shruti Aggarwal",
      ytIcon: "yt_button.png",
      link: "https://youtu.be/T7A6u7APGvc?feature=shared",
    },
    {
      image: "girish.png",
      title: "I got 100% covered for my Knee surgery!",
      name: "Girish Chaturvedi",
      ytIcon: "yt_button.png",
      link: "https://youtu.be/T7A6u7APGvc?feature=shared",
    },
  ];

  return (
    <div ref={ref}>
      <KenkoVerseTitle>Straight from the Kenkoverse</KenkoVerseTitle>
      <KenkoVerseSection>
        {kenkoVerseArr.map(({ image, link, name, title, ytIcon }) => (
          <KenkoVerseCard
            key={title}
            image={image}
            link={link}
            name={name}
            title={title}
            ytIcon={ytIcon}
          />
        ))}
      </KenkoVerseSection>
    </div>
  );
};
