import React, { ReactElement } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { useViewport } from "../../shared/hooks";
import Button from "../../shared/components/Button/Button";
import { styled } from "@mui/system";
import * as ga from "../common/UtilsGA";
import tracker from "../../shared/utils/tracker.utils";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { PHONE_LOGIN } from "../../shared/constants/enums";
import MobileInput from "../NewSignup/MobileInput";
import { clearSendOTP, generateOtp } from "../../slices/auth-slice";
import MobileOtp from "../NewSignup/SignupScreens/MobileOTP";

const DialogWrapper = styled(Dialog)`
  .MuiPaper-root {
    max-height: 533px;
    min-height: 400px;
    background-color: #ffffff;
    border-radius: 16px;
    padding: 35px 40px;
    z-index: 99;
    position: relative;
    @media (max-width: 600px) {
      padding: 16px 0px;
    }
  }

  .MuiDialogContent-root {
    height: 60vh;
  }
`;
const SendOTPBtnWrapper = styled(Button)`
  background: #fbce60;
  width: 100%;
  max-width: 176px;
  height: 52px;
  display: flex;
  justify-content: center;
  border-radius: 8px !important;
  font-size: 20px !important;
  line-height: 18px !important;
  font-family: "Figtree", sans-serif !important;
  .MuiButtonBase-root:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    font-family: "Figtree", sans-serif !important;
    font-weight: 600;
  }
  @media (max-width: 600px) {
    width: 100%;
    height: 52px;
    font-size: 16px !important;
    font-weight: 600 !important;
    max-width: 456px;
  }
`;

export const PlanLoginFlow = (props: PlanLoginFlowProps): ReactElement => {
  const auth = useSelector((state: any) => state.auth);

  const matchesSM = useViewport(600).below;
  const dispatch = useDispatch<any>();
  const [error, setError] = useState("");
  const [mobile, setMobile] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const handleSubmit = (e: any) => {
    e?.preventDefault();

    if (!mobile) return;

    tracker.pushAll("web_login_continue_button_clicked", {
      mobile_number: mobile,
    });

    console.log(inputFocused, props);

    dispatch(
      generateOtp({
        phone: mobile,
        type: PHONE_LOGIN,
      })
    )
      .unwrap()
      .then(() => {
        setOtpSent(true);
        setInputFocused(false);
      })
      .catch((error: any) => {
        setError(error?.errMsg || error?.response?.data?.errMsg);
      });
  };
  return (
    <>
      {auth?.userInfo?.user ? (
        ""
      ) : (
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={6} lg={4}>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Grid
                container
                justifyContent={matchesSM ? "space-around" : "flex-start"}
                spacing={4}
              >
                <Grid
                  item
                  xs={9}
                  sm={7}
                  style={
                    matchesSM ? { marginTop: "16px", marginLeft: "14px" } : {}
                  }
                >
                  <MobileInput
                    setInputFocused={setInputFocused}
                    mobile={mobile}
                    setMobile={setMobile}
                    error={error}
                    setError={setError}
                    parent="home-page"
                  />
                </Grid>

                <Grid
                  item
                  xs={9}
                  sm={4}
                  style={
                    matchesSM ? { marginTop: "-4px", marginLeft: "14px" } : {}
                  }
                >
                  <SendOTPBtnWrapper
                    label="Send OTP"
                    type="submit"
                    disabled={error || !mobile}
                    size="xl"
                    style={{ fontWeight: "600", boxShadow: "unset" }}
                    onClick={() => {
                      ga.event({
                        action: "web_home-send_otp_clicked",
                        params: {
                          Page_Url: window.location.href,
                          Location: "HeroSection",
                        },
                      });
                      window?.Moengage?.track_event(
                        "web_home-send_otp_clicked",
                        {
                          Page_Url: window.location.href,
                          Location: "HeroSection",
                        }
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <DialogWrapper open={otpSent} onClose={setOtpSent}>
                <DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      dispatch(clearSendOTP());
                      setOtpSent(false);
                    }}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <MobileOtp
                    setInputFocused={setInputFocused}
                    parent="dialog"
                    dialogClose={setOtpSent}
                  />
                </DialogContent>
              </DialogWrapper>
            </form>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PlanLoginFlow;

type PlanLoginFlowProps = {} & typeof defaultProps;

const defaultProps = {};

PlanLoginFlow.defaultProps = defaultProps;
