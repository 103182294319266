import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter, useViewport } from "../../../shared/hooks";

import styled from "@emotion/styled";
import { desktopPadding } from "..";
import {
  // gray800,
  // gray900,
  kenkoPureWhite,
} from "../../../shared/styles/colors";
import { kenkoDeepTeal } from "../../../shared/assets/colors";
import { Grid } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";

import * as ga from "../../common/UtilsGA";

// import Typography from "../../../shared/components/Typography/Typography";
import Button from "../../../shared/components/Button/Button";
import { mobilePadding } from "../../../shared/constants/tokens";

import { events, routes } from "./../../../shared/constants";
import { shouldRedirectToNewFlow } from "../../../shared/utils/helper.utils";
import PlanLoginFlow from "../../common/PlanLoginFlow";
import NewPlanCard from "../../../shared/components/PlanCard/NewPlanCard";
import {
  backendDrivenEvents,
  clearOnboarding,
  setGroupId,
  setGroupName,
  updateUserState,
} from "../../../slices/onboarding.slice";
import tracker from "../../../shared/utils/tracker.utils";
import GA from "../../../shared/utils/ga.utils";
import { backendEvents } from "../../../shared/constants/backend-events";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useInView } from "react-intersection-observer";
import Typography from "../../../shared/components/Typography/Typography";

export const PlansSectionWrapper = styled(Grid)`
  background: ${kenkoPureWhite};
  width: 100%;

  .table-container {
    position: absolute;
    top: -140px;
    left: 50%;
    transform: translateX(-50%);
    width: 975px;
    box-shadow: 0px 1px 5px rgba(183, 183, 183, 0.2);
    border-radius: 8px;
    background-color: ${kenkoPureWhite};
    box-shadow: 0px 18px 38px rgba(123, 123, 123, 0.14);
    border-radius: 20px;

    @media (max-width: 1000px) {
      width: 80vw;
    }

    @media (max-width: 600px) {
      top: -50px;
      width: 90vw;
    }
  }

  .table-head {
    background: ${kenkoPureWhite};
  }

  .table-head-cell {
    font-weight: 700;
    font-size: 18px;
    color: ${kenkoDeepTeal};
    text-align: center;
    padding: 20px;
    border: 1px solid #eeebce;

    @media (max-width: 600px) {
      font-size: 16px;
      padding: 15px 10px;
    }
  }

  .table-row-cell {
    font-weight: 500;
    font-size: 16px;
    color: ${kenkoDeepTeal};
    padding: 20px;
    border: 0.8px solid rgba(235, 235, 237, 0.8);
    background-color: ${kenkoPureWhite};
    min-height: 109px;

    @media (max-width: 600px) {
      font-size: 14px;
      padding: 15px 10px;
      height: 130px;
    }
  }

  .table-row-spaned {
    vertical-align: top;
  }

  .benefit-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    min-width: 250px;
  }

  .subbenefit-container {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 600px) {
      min-height: 112.8px;
    }
  }

  .subbenefit-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 12px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: unset;
    }
  }

  .subbenefit-factor {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin-left: 26px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .subbenefit-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-left: 26px;

    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  .benefit-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 7px;

    @media (max-width: 600px) {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .benefit-subtitle {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;

    .benefit-cover {
      @media (max-width: 600px) {
        font-weight: 500;
        font-size: 10px;
        line-height: 17px;
      }
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .benefit-content {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
`;

// const PlansHeader = styled(Grid)({
//   display: "flex",
//   flexDirection: "column",
//   width: "100%",
// });

// const PlansTitle = styled.h2({
//   textAlign: "center",
//   color: gray900,
//   margin: 0,
// });

// const PlansSubTitle = styled(Typography)({
//   textAlign: "center",
//   fontWeight: "500",
//   color: gray800,
// });

const PlanListContainer = styled(Grid)({
  height: "max-content",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const PlanButton = styled(Button)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "600 !important",
});

export const PlanSecondaryAction = styled(Button)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "600 !important",
});

const PlanSectionAction = styled(Button)`
  /* padding: 16px 62px; */
  /* display: flex;
  width: 308px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  margin: 60px 0;
  border-radius: 8px !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  background-color: #fff !important;
  color: #002f31;
  border: 2px solid #002f31 !important;

  @media (max-width: 600px) {
    width: 184px;
    height: 44px;
    font-size: 16px !important;
    margin: 0;
  } */
`;

export const NewPlansSection = ({ homePage, plans, parent }: any) => {
  const router = useRouter();
  const isPlanSelection = useMemo(
    () => router.pathname === "/plan-selection",
    [router.pathname]
  );

  // const matchesXL = useViewport(1536).below;
  const below600 = useViewport(600).below;
  // const matchesXS = useViewport(600).below;
  const matchesXS = useViewport(600).below;

  const { groupId } = useSelector((state: any) => state.onboarding);
  const auth = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const { ref, inView } = useInView();

  const utm = useSelector((state: any) => state.utmReducer);

  const newFlow = shouldRedirectToNewFlow(utm);

  const planBuyNowEvents = (groupName: string) => {
    if (!groupName) return;

    if (homePage) {
      switch (groupName) {
        case "forever":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.HOMEPAGE_FOREVER_BUYNOW_CLICKED,
            })
          );
          break;
        case "care":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.HOMEPAGE_CARE_BUYNOW_CLICKED,
            })
          );
          break;
        case "everyday":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.HOMEPAGE_EVERYDAY_BUYNOW_CLICKED,
            })
          );
          break;
      }
    } else {
      // Plan selection page
      switch (groupName) {
        case "forever":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.PLANSELECTION_FOREVER_BUYNOW_CLICKED,
            })
          );
          break;
        case "care":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.PLANSELECTION_CARE_BUYNOW_CLICKED,
            })
          );
          break;
        case "everyday":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.PLANSELECTION_EVERYDAY_BUYNOW_CLICKED,
            })
          );
          break;
      }
    }
  };

  const planLearnMoreEvents = (groupName: string) => {
    if (!groupName) return;

    if (homePage) {
      switch (groupName) {
        case "forever":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.HOMEPAGE_FOREVER_LEARNMORE_CLICKED,
            })
          );
          break;
        case "care":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.HOMEPAGE_CARE_LEARNMORE_CLICKED,
            })
          );
          break;
        case "everyday":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.HOMEPAGE_EVERYDAY_LEARNMORE_CLICKED,
            })
          );
          break;
      }
    } else {
      // Plan selection page
      switch (groupName) {
        case "forever":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.PLANSELECTION_FOREVER_LEARNMORE_CLICKED,
            })
          );
          break;
        case "care":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.PLANSELECTION_CARE_LEARNMORE_CLICKED,
            })
          );
          break;
        case "everyday":
          dispatch(
            backendDrivenEvents({
              ...backendEvents.PLANSELECTION_EVERYDAY_LEARNMORE_CLICKED,
            })
          );
          break;
      }
    }
  };

  useEffect(() => {
    if (inView && homePage) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_PLAN_SECTION,
        })
      );
    } else if (inView && !homePage) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.PLANSELECTION_VIEW,
        })
      );
    }
  }, [inView, dispatch, homePage]);

  const PlansHeader = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "0",
  });

  const PlansTitle = styled.p`
    color: #003032;
    text-align: center;
    font-family: Poppins;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 114.286% */
    margin: 0 0 0 0;

    @media (max-width: 600px) {
      font-size: 24px;
      font-family: Figtree;
    }
  `;

  const PlansSubTitle = styled.p`
    color: #404040;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    margin: 0.5rem 0 0 0;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  `;

  return (
    <PlansSectionWrapper container ref={ref}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: `${isPlanSelection ? (matchesXS ? "0" : "5rem") : ""}`,
          background: `${!isPlanSelection ? "#FAF9F2" : kenkoPureWhite}`,
          padding: below600
            ? `28px ${mobilePadding}`
            : `36px ${desktopPadding} 56px ${desktopPadding}`,
        }}
      >
        {newFlow && (
          <PlansHeader item xs={12}>
            <PlansTitle>Our plans</PlansTitle>
            <PlansSubTitle>
              Plans which help you save money everyday
            </PlansSubTitle>
          </PlansHeader>
        )}

        <PlanListContainer
          item
          xs={12}
          style={{
            padding: below600 ? "32px 0 0 0" : `56px ${desktopPadding}`,
            flexDirection: below600 ? "column" : "row",
            gap: below600 ? "32px" : "24px",
          }}
        >
          <>
            {plans?.planGroups?.length > 0 &&
              [...plans?.planGroups]
                ?.sort((a, b) => b.index - a.index)
                ?.map((item: any) => (
                  <NewPlanCard
                    key={item?._id}
                    name={item?.groupDisplayName || item?.groupName}
                    subTitle={item?.subTitle || ""}
                    bestSeller={item?.bestSeller}
                    price={item?.pricePerMonth}
                    discount={item?.discount}
                    benefits={item?.benefits}
                    duration={"month"}
                    colorScheme={item?.groupUIMetadata?.colorPalette}
                    image={item?.groupUIMetadata?.images?.cardIcon}
                    height={below600 ? "495px" : "560px"}
                    width={below600 ? "343px" : "384px"}
                    primaryAction={
                      <PlanButton
                        label="Buy now"
                        color={
                          item?.groupUIMetadata?.colorPalette
                            ?.primaryCtaTextColor
                        }
                        style={{
                          boxShadow: "unset",
                          color: "#FFF",
                          width: below600 ? "150px" : "162px",
                          height: below600 ? "40px" : "48px",
                          fontSize: below600 ? "12px" : "16px",
                          backgroundColor:
                            item?.groupUIMetadata?.colorPalette
                              ?.primaryCtaColor,
                        }}
                        onClick={() => {
                          dispatch(clearOnboarding());
                          dispatch(setGroupId(item?.groupId));
                          dispatch(setGroupName(item?.groupName));

                          const stepperConfig = item?.stepperConfiguration;

                          GA.event({
                            action: `homepage_${item?.groupName?.toLowerCase()}_buynow_clicked__`,
                            params: {
                              event_category: "Button Click",
                              event_label: "Buy Now Button",
                            },
                          });

                          planBuyNowEvents(
                            item?.groupDisplayName?.toLowerCase()
                          );

                          if (homePage) {
                            // Home page
                            tracker.pushAll(
                              `homepage_${item?.groupName?.toLowerCase()}_buynow_clicked`
                            );
                          } else {
                            // Plan selection page
                            tracker.pushAll(
                              `planselection_${item?.groupName?.toLowerCase()}_buynow_clicked`
                            );
                          }

                          if (auth?.userInfo) {
                            dispatch(
                              updateUserState({
                                page: homePage ? "homepage" : "plan_selection",
                                action: `plan_buynow_${item?.groupName
                                  ?.replace(" ", "_")
                                  ?.toLowerCase()}`,
                                groupId: item?.groupId,
                              })
                            );

                            if (
                              !stepperConfig?.dependents &&
                              !stepperConfig.declaration
                            ) {
                              router.push(routes.PURCHASE_PATH_CHECKOUT);
                            } else if (!stepperConfig?.dependents) {
                              router.push(routes.PURCHASE_PATH_QUIZ);
                            } else {
                              router.push(routes.PURCHASE_PATH_DEPENDENTS);
                            }
                          } else {
                            router.push(routes.HERO_SIGNUP_PAGE);
                          }
                        }}
                      />
                    }
                    secondaryAction={
                      <PlanSecondaryAction
                        label="Learn more"
                        variant="outlined"
                        color={
                          item?.groupUIMetadata?.colorPalette
                            ?.secondaryCtaTextColor
                        }
                        style={{
                          boxShadow: "unset",
                          width: below600 ? "150px" : "162px",
                          height: below600 ? "40px" : "48px",
                          fontSize: below600 ? "12px" : "16px",
                          backgroundColor:
                            item?.groupUIMetadata?.colorPalette
                              ?.secondaryCtaColor,
                          border: `1px solid ${item?.groupUIMetadata?.colorPalette?.secondaryCtaTextColor}`,
                        }}
                        onClick={() => {
                          dispatch(setGroupName(item?.groupName));

                          planLearnMoreEvents(
                            item?.groupDisplayName?.toLowerCase()
                          );

                          if (homePage) {
                            // Home page
                            tracker.pushWeb(
                              `homepage_${item?.groupName?.toLowerCase()}_learnmore_clicked`
                            );
                          } else {
                            // Plan selection page
                            tracker.pushWeb(
                              `planselection_${item?.groupName?.toLowerCase()}_learnmore_clicked`
                            );
                          }

                          if (auth?.userInfo) {
                            dispatch(
                              updateUserState({
                                page: homePage ? "homepage" : "plan_selection",
                                action: `plan_learnmore_${item?.groupName
                                  ?.replace(" ", "_")
                                  ?.toLowerCase()}`,
                                groupId: item?.groupId,
                              })
                            );
                          }

                          router.push(item?.planPageURL);
                        }}
                      />
                    }
                  />
                ))}
          </>
        </PlanListContainer>

        <Grid
          item
          xs={12}
          style={{
            marginTop: below600 ? "50px" : 0,
            marginBottom: below600 ? 0 : "50px",
          }}
        >
          <Typography
            style={{ textAlign: "center", fontSize: "16px", color: "#586574" }}
          >
            Hospital benefit is provided through partnership with leading
            hospitals in India or via reimbursements for non-partnered
            hospitals.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: below600 ? "2rem" : "",
          }}
        >
          {auth?.userInfo || parent !== "login-page" ? (
            <PlanSectionAction
              label="Compare plans"
              variant="outlined"
              size={below600 ? "lg" : "xl"}
              style={{
                boxShadow: "unset",
                padding: "16px 62px",
                borderRadius: "8px",
                fontWeight: 600,
                border: "none",
                fontFamily: "Figtree",
                background: "#FBCE60",
              }}
              onClick={() => {
                ga.event({
                  action: "web_view_more_plans_clicked",
                  params: {
                    Page_Url: window.location.href,
                  },
                });
                window?.Moengage?.track_event("web_view_more_plans_clicked", {
                  Page_Url: window.location.href,
                });

                if (homePage) {
                  // Home page
                  tracker.pushWeb(events.HOMEPAGE_COMPARE_PLANS);

                  dispatch(
                    backendDrivenEvents({
                      ...backendEvents.HOMEPAGE_COMPAREPLANS_CLICKED,
                    })
                  );
                } else {
                  // Plan selection page
                  tracker.pushWeb(events.PLAN_SELECTION_COMPARE_PLANS);

                  dispatch(
                    backendDrivenEvents({
                      ...backendEvents.PLANSELECTION_COMPAREPLANS_CLICKED,
                    })
                  );
                }

                if (auth?.userInfo) {
                  dispatch(
                    updateUserState({
                      page: homePage ? "homepage" : "plan_selection",
                      action: "compareplans_clicked",
                      groupId,
                    })
                  );
                }

                router.push(routes.COMPARE_PLANS);
              }}
            />
          ) : (
            <PlanLoginFlow />
          )}
        </Grid>
      </div>
    </PlansSectionWrapper>
  );
};

export default NewPlansSection;

// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useRouter, useViewport } from "../../../shared/hooks";

// import styled from "@emotion/styled";
// import { kenkoPureWhite } from "../../../shared/styles/colors";
// import { kenkoDeepTeal } from "../../../shared/assets/colors";
// import { Grid } from "@mui/material";
// import "swiper/css";
// import "swiper/css/navigation";

// import * as ga from "../../common/UtilsGA";

// import Button from "../../../shared/components/Button/Button";

// import { events, routes } from "./../../../shared/constants";
// import { shouldRedirectToNewFlow } from "../../../shared/utils/helper.utils";
// import PlanLoginFlow from "../../common/PlanLoginFlow";
// import NewPlanCard from "../../../shared/components/PlanCard/NewPlanCard";
// import {
//   backendDrivenEvents,
//   clearOnboarding,
//   setGroupId,
//   setGroupName,
//   updateUserState,
// } from "../../../slices/onboarding.slice";
// import tracker from "../../../shared/utils/tracker.utils";
// import GA from "../../../shared/utils/ga.utils";
// import { backendEvents } from "../../../shared/constants/backend-events";
// // eslint-disable-next-line import/no-extraneous-dependencies
// import { useInView } from "react-intersection-observer";

// export const PlansSectionWrapper = styled(Grid)`
//   background: #faf9f2;
//   width: 100%;
//   padding-bottom: 80px;

//   .table-container {
//     position: absolute;
//     top: -140px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 975px;
//     box-shadow: 0px 1px 5px rgba(183, 183, 183, 0.2);
//     border-radius: 8px;
//     background-color: ${kenkoPureWhite};
//     box-shadow: 0px 18px 38px rgba(123, 123, 123, 0.14);
//     border-radius: 20px;

//     @media (max-width: 1000px) {
//       width: 80vw;
//     }

//     @media (max-width: 600px) {
//       top: -50px;
//       width: 90vw;
//     }
//   }

//   .table-head {
//     background: ${kenkoPureWhite};
//   }

//   .table-head-cell {
//     font-weight: 700;
//     font-size: 18px;
//     color: ${kenkoDeepTeal};
//     text-align: center;
//     padding: 20px;
//     border: 1px solid #eeebce;

//     @media (max-width: 600px) {
//       font-size: 16px;
//       padding: 15px 10px;
//     }
//   }

//   .table-row-cell {
//     font-weight: 500;
//     font-size: 16px;
//     color: ${kenkoDeepTeal};
//     padding: 20px;
//     border: 0.8px solid rgba(235, 235, 237, 0.8);
//     background-color: ${kenkoPureWhite};
//     min-height: 109px;

//     @media (max-width: 600px) {
//       font-size: 14px;
//       padding: 15px 10px;
//       height: 130px;
//     }
//   }

//   .table-row-spaned {
//     vertical-align: top;
//   }

//   .benefit-name {
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 17px;
//     min-width: 250px;
//   }

//   .subbenefit-container {
//     display: flex;
//     align-items: center;
//     gap: 10px;

//     @media (max-width: 600px) {
//       min-height: 112.8px;
//     }
//   }

//   .subbenefit-name {
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 12px;

//     @media (max-width: 600px) {
//       font-size: 14px;
//       line-height: unset;
//     }
//   }

//   .subbenefit-factor {
//     font-weight: 600;
//     font-size: 20px;
//     line-height: 22px;
//     margin-left: 26px;

//     @media (max-width: 600px) {
//       font-size: 14px;
//     }
//   }

//   .subbenefit-description {
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 19px;
//     margin-left: 26px;

//     @media (max-width: 600px) {
//       font-size: 10px;
//     }
//   }

//   .benefit-title {
//     font-weight: 500;
//     font-size: 16px;
//     line-height: 20px;
//     margin-bottom: 7px;

//     @media (max-width: 600px) {
//       font-size: 14px;
//       margin-bottom: 0;
//     }
//   }

//   .benefit-subtitle {
//     font-weight: 700;
//     font-size: 15px;
//     line-height: 20px;

//     .benefit-cover {
//       @media (max-width: 600px) {
//         font-weight: 500;
//         font-size: 10px;
//         line-height: 17px;
//       }
//     }

//     @media (max-width: 600px) {
//       font-size: 14px;
//     }
//   }

//   .benefit-content {
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 20px;

//     @media (max-width: 600px) {
//       font-size: 10px;
//     }
//   }
// `;

// const PlansHeader = styled(Grid)({
//   display: "flex",
//   flexDirection: "column",
//   width: "100%",
// });

// const PlansTitle = styled.h2({
//   marginTop: "80px",
//   color: "#243242",
//   textAlign: "center",
//   fontFamily: "Figtree",
//   fontSize: "42px",
//   fontWeight: 600,
//   padding: `80px 0 64px 0`,
//   margin: 0,

//   "@media (max-width: 600px)": {
//     fontSize: "20px",
//     width: "70%",
//     margin: "48px auto",
//     padding: 0,
//   },
// });

// const PlanListContainer = styled.div`
//   display: grid;
//   grid-template-columns: repeat(3, 300px);
//   grid-template-rows: 1fr;
//   gap: 75px;

//   @media (max-width: 600px) {
//     width: 100%;
//     display: flex;
//     overflow-x: auto;
//     gap: 32px;
//     padding: 1rem 32px;
//   }

//   ::-webkit-scrollbar {
//     width: 0;
//   }
// `;

// export const PlanButton = styled(Button)({
//   color: "#404040",
//   fontFamily: "Figtree",
// });

// export const PlanSecondaryAction = styled(Button)({
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   fontWeight: "600 !important",
// });

// export const ComparePlanButton = styled.button`
//   cursor: pointer;
//   color: #003032;
//   font-family: Figtree;
//   font-size: 20px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 125%;
//   background: transparent;
//   background-color: #fbce60;
//   border: none;
//   outline: none;
//   padding: 16px 32px;
//   border-radius: 8px;
//   margin-top: 64px;
// `;

// export const NewPlansSection = ({ homePage, plans, parent }: any) => {
//   const router = useRouter();

//   const below600 = useViewport(600).below;

//   const { groupId } = useSelector((state: any) => state.onboarding);
//   const auth = useSelector((state: any) => state.auth);
//   const dispatch = useDispatch();
//   const { ref, inView } = useInView();

//   const utm = useSelector((state: any) => state.utmReducer);

//   const newFlow = shouldRedirectToNewFlow(utm);

//   const getPlanColor = (index: number) => {
//     switch (index) {
//       case 0:
//         return "#FF6F55";

//       case 1:
//         return "#BADFD8";

//       case 2:
//         return "#BED0A2";
//     }
//   };

//   const planBuyNowEvents = (groupName: string) => {
//     if (!groupName) return;

//     if (homePage) {
//       switch (groupName) {
//         case "forever":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.HOMEPAGE_FOREVER_BUYNOW_CLICKED,
//             })
//           );
//           break;
//         case "care":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.HOMEPAGE_CARE_BUYNOW_CLICKED,
//             })
//           );
//           break;
//         case "everyday":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.HOMEPAGE_EVERYDAY_BUYNOW_CLICKED,
//             })
//           );
//           break;
//       }
//     } else {
//       // Plan selection page
//       switch (groupName) {
//         case "forever":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.PLANSELECTION_FOREVER_BUYNOW_CLICKED,
//             })
//           );
//           break;
//         case "care":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.PLANSELECTION_CARE_BUYNOW_CLICKED,
//             })
//           );
//           break;
//         case "everyday":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.PLANSELECTION_EVERYDAY_BUYNOW_CLICKED,
//             })
//           );
//           break;
//       }
//     }
//   };

//   const planLearnMoreEvents = (groupName: string) => {
//     if (!groupName) return;

//     if (homePage) {
//       switch (groupName) {
//         case "forever":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.HOMEPAGE_FOREVER_LEARNMORE_CLICKED,
//             })
//           );
//           break;
//         case "care":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.HOMEPAGE_CARE_LEARNMORE_CLICKED,
//             })
//           );
//           break;
//         case "everyday":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.HOMEPAGE_EVERYDAY_LEARNMORE_CLICKED,
//             })
//           );
//           break;
//       }
//     } else {
//       // Plan selection page
//       switch (groupName) {
//         case "forever":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.PLANSELECTION_FOREVER_LEARNMORE_CLICKED,
//             })
//           );
//           break;
//         case "care":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.PLANSELECTION_CARE_LEARNMORE_CLICKED,
//             })
//           );
//           break;
//         case "everyday":
//           dispatch(
//             backendDrivenEvents({
//               ...backendEvents.PLANSELECTION_EVERYDAY_LEARNMORE_CLICKED,
//             })
//           );
//           break;
//       }
//     }
//   };

//   useEffect(() => {
//     if (inView && homePage) {
//       dispatch(
//         backendDrivenEvents({
//           ...backendEvents.HOMEPAGE_OURPLANS,
//         })
//       );
//     } else if (inView && !homePage) {
//       dispatch(
//         backendDrivenEvents({
//           ...backendEvents.PLANSELECTION_VIEW,
//         })
//       );
//     }
//   }, [inView, dispatch, homePage]);

//   return (
//     <PlansSectionWrapper container ref={ref}>
//       <div
//         style={{
//           width: "100%",
//           height: "100%",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           flexDirection: "column",
//         }}
//       >
//         {newFlow && (
//           <PlansHeader item xs={12}>
//             <PlansTitle>
//               Affordable subscriptions for total healthcare
//             </PlansTitle>
//           </PlansHeader>
//         )}

//         <PlanListContainer>
//           {plans?.planGroups?.length > 0 &&
//             [...plans?.planGroups]
//               ?.sort((a, b) => a.index - b.index)
//               ?.map((item: any, index) => (
//                 <NewPlanCard
//                   key={item?._id}
//                   name={item?.groupDisplayName || item?.groupName}
//                   subTitle={item?.subTitle || ""}
//                   bestSeller={item?.bestSeller}
//                   price={item?.pricePerMonth}
//                   discount={item?.discount}
//                   benefits={item?.benefits}
//                   colorScheme={getPlanColor(index)}
//                   image={item?.groupUIMetadata?.images?.cardIcon}
//                   primaryAction={
//                     <button
//                       className="primary-button"
//                       onClick={() => {
//                         dispatch(clearOnboarding());
//                         dispatch(setGroupId(item?.groupId));
//                         dispatch(setGroupName(item?.groupName));

//                         const stepperConfig = item?.stepperConfiguration;

//                         GA.event({
//                           action: `homepage_${item?.groupName?.toLowerCase()}_buynow_clicked__`,
//                           params: {
//                             event_category: "Button Click",
//                             event_label: "Buy Now Button",
//                           },
//                         });

//                         planBuyNowEvents(item?.groupDisplayName?.toLowerCase());

//                         if (homePage) {
//                           // Home page
//                           tracker.pushAll(
//                             `homepage_${item?.groupName?.toLowerCase()}_buynow_clicked`
//                           );
//                         } else {
//                           // Plan selection page
//                           tracker.pushAll(
//                             `planselection_${item?.groupName?.toLowerCase()}_buynow_clicked`
//                           );
//                         }

//                         if (auth?.userInfo) {
//                           dispatch(
//                             updateUserState({
//                               page: homePage ? "homepage" : "plan_selection",
//                               action: `plan_buynow_${item?.groupName
//                                 ?.replace(" ", "_")
//                                 ?.toLowerCase()}`,
//                               groupId: item?.groupId,
//                             })
//                           );

//                           if (
//                             !stepperConfig?.dependents &&
//                             !stepperConfig.declaration
//                           ) {
//                             router.push(routes.PURCHASE_PATH_CHECKOUT);
//                           } else if (!stepperConfig?.dependents) {
//                             router.push(routes.PURCHASE_PATH_QUIZ);
//                           } else {
//                             router.push(routes.PURCHASE_PATH_DEPENDENTS);
//                           }
//                         } else {
//                           router.push(routes.HERO_SIGNUP_PAGE);
//                         }
//                       }}
//                     >
//                       Buy now
//                     </button>
//                   }
//                   secondaryAction={
//                     <button
//                       className="secondary-button"
//                       onClick={() => {
//                         dispatch(setGroupName(item?.groupName));

//                         planLearnMoreEvents(
//                           item?.groupDisplayName?.toLowerCase()
//                         );

//                         if (homePage) {
//                           // Home page
//                           tracker.pushWeb(
//                             `homepage_${item?.groupName?.toLowerCase()}_learnmore_clicked`
//                           );
//                         } else {
//                           // Plan selection page
//                           tracker.pushWeb(
//                             `planselection_${item?.groupName?.toLowerCase()}_learnmore_clicked`
//                           );
//                         }

//                         if (auth?.userInfo) {
//                           dispatch(
//                             updateUserState({
//                               page: homePage ? "homepage" : "plan_selection",
//                               action: `plan_learnmore_${item?.groupName
//                                 ?.replace(" ", "_")
//                                 ?.toLowerCase()}`,
//                               groupId: item?.groupId,
//                             })
//                           );
//                         }

//                         router.push(item?.planPageURL);
//                       }}
//                     >
//                       Learn more
//                     </button>
//                   }
//                 />
//               ))}
//         </PlanListContainer>

//         <Grid
//           item
//           xs={12}
//           style={{
//             top: !below600
//               ? parent == "login-page"
//                 ? "750px"
//                 : "680px"
//               : parent == "login-page"
//               ? "496px"
//               : "540px",
//             zIndex: 3,
//             display: "flex",
//             justifyContent: "center",
//             width: "100%",
//             marginTop: "30px",
//           }}
//         >
//           {auth?.userInfo || parent !== "login-page" ? (
//             <ComparePlanButton
//               onClick={() => {
//                 ga.event({
//                   action: "web_view_more_plans_clicked",
//                   params: {
//                     Page_Url: window.location.href,
//                   },
//                 });
//                 window?.Moengage?.track_event("web_view_more_plans_clicked", {
//                   Page_Url: window.location.href,
//                 });

//                 if (homePage) {
//                   // Home page
//                   tracker.pushWeb(events.HOMEPAGE_COMPARE_PLANS);

//                   dispatch(
//                     backendDrivenEvents({
//                       ...backendEvents.HOMEPAGE_COMPAREPLANS_CLICKED,
//                     })
//                   );
//                 } else {
//                   // Plan selection page
//                   tracker.pushWeb(events.PLAN_SELECTION_COMPARE_PLANS);

//                   dispatch(
//                     backendDrivenEvents({
//                       ...backendEvents.PLANSELECTION_COMPAREPLANS_CLICKED,
//                     })
//                   );
//                 }

//                 if (auth?.userInfo) {
//                   dispatch(
//                     updateUserState({
//                       page: homePage ? "homepage" : "plan_selection",
//                       action: "compareplans_clicked",
//                       groupId,
//                     })
//                   );
//                 }

//                 router.push(routes.COMPARE_PLANS);
//               }}
//             >
//               Compare plans
//             </ComparePlanButton>
//           ) : (
//             <PlanLoginFlow />
//           )}
//         </Grid>
//       </div>
//     </PlansSectionWrapper>
//   );
// };

// export default NewPlansSection;
