export const benefitsArray = [
  {
    image: "doctor_fees.svg",
    altText: "Doctor fees",
    title: "Doctor fees",
    description:
      "Visit a doctor or  consult them virtually - we got you covered.",
  },
  {
    image: "medicine_bills.svg",
    altText: "medicine bills",
    title: "Medicine bills",
    description: "We’ll pay for all of your medical bills.",
  },
  {
    image: "lab_tests.svg",
    altText: "lab tests",
    title: "Lab tests",
    description:
      "Radiology, ECG or any diagnostic test - we’ll take care of it.",
  },
  {
    image: "shoho_store.svg",
    altText: "shoho store",
    title: "Daily essentials",
    description:
      "Baby care, personal care, diabetic care, skin care, nutrition & more!",
  },
  {
    image: "hospitalization.svg",
    altText: "hospitalization",
    title: "Hospitalisation",
    description: "20 Lac+ coverage with trucashless across 600+ hospitals.",
  },
];
