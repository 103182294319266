export const pageContent299 = {
  IMAGE: "articles/299-Mini-Plan-article.svg",
  CARD_IMAGE: "articles/299-Mini-Plan.svg",
  MOBILE_IMAGE: "articles/299-Mini-Plan-mobile.svg",
  BLUR_IMAGE: "articles/299-Mini-Plan-article-blur.png",
  IMAGE_ALT: "Kenko's Mini Basic Plan benefits - Your Key to Better Health",
  TITLE: "Your Search For Basic Plan Ends Here",
  SUB_TITLE: "Mini Health Plan For ₹20/day!",
  HERO_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          The increasing number of lifestyle diseases has made us realise how
          important, albeit expensive, taking care of yourself and your loved
          ones can be. In an era of fitness watches, footstep trackers, and
          heartbeat monitors, we also see an unprecedented rise in PCOS,
          diabetes, and heart diseases.
        </p>
      ),
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          The bright side is that we’ve also been treating numerous illnesses
          with ease now that were untreatable in the past. Unfortunately, this
          has resulted in a skyrocketing increase in medical expenses. A simple
          cold can cost thousands today when you account for the doctor’s fees,
          medicine costs, and laboratory tests.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          All these expenses are usually not covered by your basic plan. They
          only cover hospitalization expenses. They don’t cover OPD expenses.
          Wondering how you can tackle them? Simple! get an OPD plan.
        </p>
      ),
    },
  ],

  CONTENT: [
    { ID: 1, SUBHEADING: "What to expect from OPD plans?" },
    {
      ID: 2,
      CONTENT: (
        <p>
          Traditional Health Plans were basic medical plans designed to give you
          benefits for only major surgeries and chronic diseases.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          With time, the masses realized the need for comprehensive basic
          healthcare plans that help you save money even for minor treatments:
          the plans that provide OPD benefits. Kenko’s solution for this public
          demand is our 299 Mini Plan: A basic care plan that gives you
          discounts on all your OPD expenses. Here are some of the benefits you
          can expect.
        </p>
      ),
    },
    {
      ID: 4,
      CONTENT: <h3>1. Turn all your OPD bills to ZERO!</h3>,
    },
    {
      ID: 5,
      CONTENT: (
        <p>
          Data suggests that 75% of most individuals’ lifetime health expenses
          are incurred outside the hospital as OPD expenses. Thus, your
          traditional basic plans are futile when it comes to your{" "}
          <a href="https://kenkohealth.in/" target="_blank" rel="noreferrer">
            comprehensive healthcare
          </a>{" "}
          needs. These plans can't protect you from what you regularly spend
          out-of-hospital.
        </p>
      ),
    },
    {
      ID: 6,
      CONTENT: (
        <p>
          Our 299 Mini Plan is designed to give you a truly cashless OPD
          experience and tries to bring all your medical bills to zero. This
          basic healthcare plan takes care of the OPD expenses that keep popping
          up, helping you save a ton of money.
        </p>
      ),
    },
    {
      ID: 7,
      CONTENT: <h3>2. Shelter yourself from lifestyle diseases</h3>,
    },
    {
      ID: 8,
      CONTENT: (
        <p>
          Your late 20s and 30s is the period where the body slowly starts
          showing signs of wear and tear. Be it burnout, obesity, or{" "}
          <a
            href="https://kenkohealth.in/plan/Diabetes-Plan"
            target="_blank"
            rel="noreferrer"
          >
            diabetes
          </a>
          , it is usually during this phase that the first indicators of
          lifestyle problems start popping up. They may not be severe during
          this stage, which is why addressing them early helps.
        </p>
      ),
    },
    {
      ID: 9,
      CONTENT: (
        <p>
          However, the thought of shelling out money out of your pocket can hold
          you back from seeking early intervention. Sure, they may not be very
          high yet, but your primal instincts are not to want to pay for a
          doctor’s visit if you don’t have to.
        </p>
      ),
    },
    {
      ID: 10,
      CONTENT: (
        <p>
          Unless, of course, you could reduce these expenses with an OPD health
          plan. Kenko OPD Plans are tailor-made for such situations. With our
          299 Mini Plan as your basic plan, you save money on every check-up,
          test, medicines ordered, and doctor consultation, incentivising you to
          take better care of your health.
        </p>
      ),
    },
    { ID: 11, CONTENT: <h3>3. Save on preventive medication</h3> },
    {
      ID: 12,
      CONTENT: (
        <p>
          When we imagine our future, we often think of the hunky-dory stuff we
          would want to do. However, we should also consider the treatment we
          may require if our health deteriorates or we fall ill.
        </p>
      ),
    },
    {
      ID: 13,
      CONTENT: (
        <p>
          To tackle such grim situations in the future, you may want to consider
          the right consultations and medicines that can help you maintain good
          health today. You would essentially want to fortify your body to fight
          infections and diseases. This inner strength is particularly useful to
          cope with the stress-ridden lives we lead, worsened by demanding jobs
          and long commutes.
        </p>
      ),
    },
    {
      ID: 14,
      CONTENT: (
        <p>
          You would also want to invest in healthcare plans that help you save
          money today and in the future.
        </p>
      ),
    },
    {
      ID: 15,
      CONTENT: (
        <p>
          The simple solution is to subscribe to Kenko Healthcare Plans. You can
          save on essential medicines while ensuring that you remain as fit as
          possible. All our plans are designed as OPD plans that help you save
          on pharmacy costs and treatment expenses today and in the future.
        </p>
      ),
    },
    {
      ID: 16,
      CONTENT: <h3>4. Lab tests won’t suck your finances dry</h3>,
    },
    {
      ID: 17,
      CONTENT: (
        <p>
          An essential aspect of taking care of yourself and your loved ones is
          undergoing regular{" "}
          <a
            href="https://kenkohealth.in/plan/599-Individual-Plan"
            target="_blank"
            rel="noreferrer"
          >
            lab tests
          </a>
          . Tracking your blood sugar, vitamin levels, and other vital
          indicators can play an invaluable role in predicting upcoming health
          expenses.
        </p>
      ),
    },
    {
      ID: 18,
      CONTENT: (
        <p>
          Preventive medications work on the principle of certain assumptions.
          If you get regular tests and annual check-ups, the doctors get a
          higher degree of accuracy in identifying how you need to safeguard
          your body.
        </p>
      ),
    },
    {
      ID: 19,
      CONTENT: (
        <p>
          You may not be getting lab tests as they can burn a hole in your
          pockets. Thorough check-ups can cost thousands of rupees. If you get a
          Kenko 299 Mini Plan as your basic care plan, you can save on your lab
          tests and get them at laboratories with state-of-the-art services.
        </p>
      ),
    },
    {
      ID: 20,
      CONTENT: (
        <p>
          What else? You can book these tests from our app and get them done
          from the comfort of your home. And the cherry on the cake is that you
          only pay the discounted price. No need to go through lengthy
          reimbursement procedures. What more could you possibly ask for?
        </p>
      ),
    },
    {
      ID: 21,
      CONTENT: <h3>5. No need to understand complicated healthcare jargon</h3>,
    },
    {
      ID: 22,
      CONTENT: (
        <p>
          No matter how much we know, we’ve all been there: What are the EXACT
          benefits offered by my healthcare plan? Does the healthcare plan give
          me benefits on OPD expenses?{" "}
          <a
            href="http://Kenkohealth.in/plans"
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            Kenko Healthcare Plans
          </a>{" "}
          keep its documents simple so that a 10th-standard teenager can
          understand them.
        </p>
      ),
    },
    {
      ID: 23,
      CONTENT: (
        <p>
          It tells you precisely what you are saving up on and how much you will
          be saving and gives you a basic health cover that translates into
          actual day-to-day savings. It isn’t a complicated document that will
          only be useful during a major accident, still requiring you to run
          from pillar to post. It protects your pockets from everyday expenses
          as well.
        </p>
      ),
    },
  ],

  ENDING_CONTENT: [
    {
      ID: 1,
      SUBHEADING: "Commit to your well-being",
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          Let’s be honest here. This is not just about saving money on your
          medical expenses. It’s about committing to taking care of your body
          and keeping it in pristine condition.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          Just like a visit to the gym or choosing fruits over chips is an
          investment in your health, so is investing in a good basic medical
          plan that covers all your OPD expenses.
        </p>
      ),
    },
    {
      ID: 4,
      CONTENT: (
        <p>
          <em>
            Take the next step in committing to your well-being with the Kenko
            299 Mini Plan so that we can take care of the rest when you’re busy
            making all the right choices (after this one). We will give you
            benefits on doctor consultations, lab tests, medicine orders, daily
            healthcare and much more.
          </em>
        </p>
      ),
    },
  ],

  CTA_BUTTON: "Get Your Subscription Now",
  ROUTE: "/kenko-score",
};
