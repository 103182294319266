import React, { useEffect } from "react";
import { useViewport } from "../../shared/hooks";
import { benefitsArray } from "../../shared/constants/home";
import { BenefitCard } from "../../shared/components/Layout/SectionWrapper";
import { imageParser } from "../../shared/utils/image.utils";
import { backendDrivenEvents } from "../../slices/onboarding.slice";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import { backendEvents } from "../../shared/constants/backend-events";

export const BenefitSection = () => {
  const below600 = useViewport(600).below;
  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_BENEFIT_SECTION,
        })
      );
    }
  }, [inView, dispatch]);

  return (
    <div ref={ref}>
      <div className="benefit-title-container">
        <p className="benefit-section-title">
          100% Coverage. Free OPD Benefits.
        </p>
      </div>
      <div className="benefit-container">
        {benefitsArray.map(({ altText, description, image, title }) => (
          <BenefitCard key={title}>
            <div style={{ width: "100%" }} className="benefit-card">
              <div className="benefit-card-content">
                <img
                  className={`${
                    title !== "Daily essentials"
                      ? "benefit-image"
                      : "benefit-image-other"
                  } ${
                    below600 && title === "Daily essentials"
                      ? "benefit-image-mobile"
                      : ""
                  }`}
                  src={imageParser(`benefit_icons/${image}`)}
                  alt="Doctor fees"
                />
                <div>
                  <p className={"benefit-title"}>{title}</p>
                  <p className="benefit-description">{description}</p>
                </div>
              </div>
              <div className="benefit-footer">
                <img
                  src={imageParser("benefit_icons/bank_icons.svg")}
                  alt={altText}
                />
              </div>
            </div>
          </BenefitCard>
        ))}
      </div>
    </div>
  );
};
