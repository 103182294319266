import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";

// import newsStyles from "../Home/news/NewsStyle";
import { imageParser } from "../../shared/utils/image.utils";

// import { makeStyles } from "@mui/styles";
// import { useViewport } from "../../shared/hooks";
import styled from "@emotion/styled";

// @ts-ignore
// const useStyles = makeStyles(newsStyles);

function HospitalList() {
  // @ts-ignore
  // const classes = useStyles();
  // console.log(classes, "classes");

  // const matchesSM = useViewport(950).below;
  // const [loaded, setLoaded] = useState(false);
  // const settings = {
  //   className: "slider variable-width",
  //   dots: false,
  //   infinite: true,
  //   speed: 1000,
  //   slidesToShow: matchesSM ? 3 : 5,
  //   slidesToScroll: matchesSM ? 3 : 1,
  //   arrows: false,
  //   autoplay: true,
  //   autoplaySpeed: 0,
  //   cssEase: "linear",
  //   nextArrow: (
  //     <img
  //       loading="lazy"
  //       src={imageParser("Arrow 2.webp")}
  //       alt="Next Arrow"
  //       style={{ height: "17px", width: "20px" }}
  //     />
  //   ),

  //   prevArrow: (
  //     <img
  //       loading="lazy"
  //       src={imageParser("Arrow 1.webp")}
  //       alt="Previous Arrow"
  //       style={{ height: "17px", width: "20px" }}
  //     />
  //   ),
  // };

  const HospitalListWrapper = styled.div`
    position: relative;
    margin: auto;
    padding: 23px 0 19px 0;

    @keyframes slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translate(calc(-50% - 0.5rem));
      }
    }

    @keyframes slideMobile {
      from {
        transform: translateX(0);
      }
      to {
        transform: translate(calc(-50% - 0.5rem));
      }
    }

    .slide-tracker {
      display: flex;
      flex-wrap: nowrap;
      gap: 3rem;
      padding: 0;
      width: fit-content;
      animation: slide 60s linear infinite;
    }

    @media (max-width: 600px) {
      .slide-tracker img {
        width: 151px;
        height: 75px;
      }

      .slide-tracker {
        animation: slideMobile 40s linear infinite;
      }
    }
  `;

  return (
    <>
      <HospitalListWrapper>
        <div className="slide-tracker">
          <img
            src={imageParser("hospital_section/aakash.svg")}
            alt="aakash hospital"
          />
          <img
            src={imageParser("hospital_section/anandlok.svg")}
            alt="anandlok hospital"
          />
          <img
            src={imageParser("hospital_section/apollo.svg")}
            alt="apollo hospital"
          />
          <img
            src={imageParser("hospital_section/binayak.svg")}
            alt="binayak hospital"
          />
          <img
            src={imageParser("hospital_section/bosh.svg")}
            alt="bosh hospital"
          />
          <img
            src={imageParser("hospital_section/columbia_asia.svg")}
            alt="columbia hospital"
          />
          <img
            src={imageParser("hospital_section/delhi.svg")}
            alt="delhi hospital"
          />
          <img
            src={imageParser("hospital_section/fortis.svg")}
            alt="fortis hospital"
          />
          <img
            src={imageParser("hospital_section/gandhi.svg")}
            alt="gandhi hospital"
          />
          <img
            src={imageParser("hospital_section/ganga_ram.svg")}
            alt="ganga ram hospital"
          />
          <img
            src={imageParser("hospital_section/kalindi.svg")}
            alt="kalindi hospital"
          />
          <img
            src={imageParser("hospital_section/manipal.svg")}
            alt="manipal hospital"
          />
          <img
            src={imageParser("hospital_section/max.svg")}
            alt="max hospital"
          />
          <img
            src={imageParser("hospital_section/medanta.svg")}
            alt="medanta hospital"
          />
          <img src={imageParser("hospital_section/om.svg")} alt="om hospital" />
          <img
            src={imageParser("hospital_section/rajalakshmii.svg")}
            alt="rajalakshmi hospital"
          />
          <img
            src={imageParser("hospital_section/venkateshwar.svg")}
            alt="venkateshwar hospital"
          />

          <img
            src={imageParser("hospital_section/aakash.svg")}
            alt="aakash hospital"
          />
          <img
            src={imageParser("hospital_section/anandlok.svg")}
            alt="anandlok hospital"
          />
          <img
            src={imageParser("hospital_section/apollo.svg")}
            alt="apollo hospital"
          />
          <img
            src={imageParser("hospital_section/binayak.svg")}
            alt="binayak hospital"
          />
          <img
            src={imageParser("hospital_section/bosh.svg")}
            alt="bosh hospital"
          />
          <img
            src={imageParser("hospital_section/columbia_asia.svg")}
            alt="columbia hospital"
          />
          <img
            src={imageParser("hospital_section/delhi.svg")}
            alt="delhi hospital"
          />
          <img
            src={imageParser("hospital_section/fortis.svg")}
            alt="fortis hospital"
          />
          <img
            src={imageParser("hospital_section/gandhi.svg")}
            alt="gandhi hospital"
          />
          <img
            src={imageParser("hospital_section/ganga_ram.svg")}
            alt="ganga ram hospital"
          />
          <img
            src={imageParser("hospital_section/kalindi.svg")}
            alt="kalindi hospital"
          />
          <img
            src={imageParser("hospital_section/manipal.svg")}
            alt="manipal hospital"
          />
          <img
            src={imageParser("hospital_section/max.svg")}
            alt="max hospital"
          />
          <img
            src={imageParser("hospital_section/medanta.svg")}
            alt="medanta hospital"
          />
          <img src={imageParser("hospital_section/om.svg")} alt="om hospital" />
          <img
            src={imageParser("hospital_section/rajalakshmii.svg")}
            alt="rajalakshmi hospital"
          />
          <img
            src={imageParser("hospital_section/venkateshwar.svg")}
            alt="venkateshwar hospital"
          />
        </div>
      </HospitalListWrapper>
    </>
  );
}

export default HospitalList;
