import styled from "@emotion/styled";
import { kenkoDeepTeal, kenkoPureWhite } from "../../shared/assets/colors";
import {
  black400,
  black800,
  teal100,
  teal800,
} from "../../shared/styles/colors";

export const ArticleWrapper = styled.div`
  .article-section {
    padding: 48px 126px;
    background-color: ${kenkoPureWhite};
    scroll-behavior: smooth;
    @media (max-width: 600px) {
      padding: 48px 26px;
    }
  }

  .article-container {
    color: ${kenkoDeepTeal};
    position: relative;
    opacity: 0.87;
    margin: auto;
    background: ${kenkoPureWhite};
    border: 1px solid #d0d0d0;
    box-shadow: 0px 4px 6px rgba(28, 28, 28, 0.24);
    border-radius: 16px;
    color: ${black800};
    height: 234px;
    @media (max-width: 600px) {
      height: fit-content;
      padding: 12px;
    }
    @media (max-width: 900px) {
      height: fit-content;
      padding: 12px;
    }
  }

  .img-container-cls {
    width: 100%;
    padding: 18px;
    padding-right: 0;
    @media (max-width: 600px) {
      padding: 0;
    }
    @media (max-width: 900px) {
      padding: 0;
    }
  }

  .img-cls {
    width: 100%;
    border-radius: 16px;
    display: block;
    height: 200px;

    @media (max-width: 600px) {
      border-radius: 14px 14px 0px;
      margin: auto;
      display: none;
    }

    @media (max-width: 900px) {
      height: 100%;
      margin: auto;
      border-radius: 14px 14px 0px 0px;
    }
  }

  .img-mobile-cls {
    display: none;
    @media (max-width: 600px) {
      display: block;
      width: 100%;
      margin-bottom: 12px;
      border-radius: 14px 14px 0px 0px;
    }
  }

  .article-Mainheading {
    width: 100%;
    margin: auto;
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 40px;
    line-height: 42px;
    color: ${kenkoDeepTeal};
    text-align: center;
    @media (max-width: 600px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .article-subHeading {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .article-modal-subheading {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .article-content-container {
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 18px;
    overflow: none;
    color: ${black800};
    ::-webkit-scrollbar {
      width: 0.4em;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      outline: 1px solid slategrey;
      cursor: pointer;
    }
    @media (max-width: 600px) {
      padding-left: 0;
      height: fit-content;
    }

    .wrapper-class {
      margin-top: auto;
    }
  }

  .article-content {
    font-size: 14px;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 135.15px;
    @media (max-width: 600px) {
      font-size: 12px;
      -webkit-line-clamp: 4;
      height: fit-content;
    }
    @media (max-width: 900px) {
      -webkit-line-clamp: 4;
      height: fit-content;
    }
  }

  .read-more-btn {
    margin-top: 10px;
    cursor: pointer;
    padding: 8px 16px;
    gap: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    width: 121px;
    height: 38px;
    box-shadow: unset !important;
    @media (max-width: 600px) {
      display: none;
    }
  }

  .read-more-btn-mobile {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    color: ${teal800};
    display: none;
    margin-top: 1em;
    box-shadow: unset !important;
    @media (max-width: 600px) {
      display: block;
    }
  }

  .cta-btn {
    margin: 1em 0;
    width: 17em;
    display: flex;
    justify-content: center;
    background-color: #fbce60;
    color: ${kenkoDeepTeal};
    box-shadow: unset !important;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .link {
    color: #0000ee;
    text-decoration: underline;
  }

  .article-modal {
    position: fixed;
    z-index: 1000;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(68, 68, 68, 0.7);
  }

  .article-modal-title {
    width: 100%;
    margin: auto;
    font-weight: 600;
    font-size: 28px;
    color: ${kenkoDeepTeal};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 22px;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
    }
  }

  .article-modal-section {
    font-size: 14px;
    background-color: white;
    width: 50%;
    height: 100%;
    margin-left: auto;
    overflow: scroll;
    scroll-padding-top: 116px;
    @media (max-width: 600px) {
      width: 100%;
    }
    ::-webkit-scrollbar {
      width: 0.4em;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      outline: 1px solid slategrey;
      cursor: pointer;
    }

    .img-modal-cls {
      height: 240px;
      border-radius: 16px;
      @media (max-width: 600px) {
        margin-left: 0;
        width: 100%;
        max-height: 200px;
        max-width: 312px;
      }
      @media (max-width: 900px) {
        height: 160px;
      }
    }

    .article-chip {
      font-weight: 600;
      color: ${teal800};
      font-size: 14px;
      line-height: 24px;
      border: 1px solid #b6dee0;
      filter: drop-shadow(0px 4px 6px rgba(28, 28, 28, 0.2));
      border-radius: 8px;
      padding: 4px 12px;
      width: fit-content;
      cursor: pointer;
    }

    .article-selected-chip {
      background: ${teal100};
    }

    .article-chips-container {
      display: flex;
      flex-direction: row;
      margin: 28px 0;
      gap: 10px;
      width: 100%;
      flex-wrap: wrap;
      @media (max-width: 600px) {
        display: none;
      }
    }

    .article-modal-close-btn {
      top: 21px;
      right: 21px;
      cursor: pointer;
      color: ${black400};
      @media (max-width: 600px) {
        width: 10%;
        left: 8px;
      }
    }

    .article-modal-title-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${kenkoPureWhite};
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
      margin-left: auto;
      z-index: 1300;
      position: sticky;
      box-shadow: 0px 2px 5px rgba(160, 160, 160, 0.25);
      padding: 18px;
      @media (max-width: 600px) {
        width: 100%;
        gap: 10px;
        background: ${kenkoPureWhite};
        top: 0;
        z-index: 1300;
        position: sticky;
        box-shadow: 0px 2px 5px rgba(160, 160, 160, 0.25);
        padding: 18px 60px 8px 5px;
        flex-direction: row-reverse;
      }
    }

    .article-modal-content-container {
      padding: 26px 40px 0px 40px;
      width: 100%;
      color: ${black800};
      @media (max-width: 600px) {
        padding: 21px;
      }
    }
  }

  .article-modal-hidden-content {
    display: none;
  }

  .article-modal-image-container {
    display: flex;
  }

  .article-modal-content h2,
  .article-modal-content h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    @media (max-width: 600px) {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .article-modal-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    @media (max-width: 600px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
