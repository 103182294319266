import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { Dialog } from "@mui/material";

import Typography from "../../shared/components/Typography/Typography";
import { toggleDownloadAppModal } from "../../slices/onboarding.slice";
import { imageParser } from "../../shared/utils/image.utils";
import { useViewport } from "../../shared/hooks";
import { DownloadButtons } from "./DownloadButtons";

const DownloadAppModalWrapper = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 16px;
    padding: 40px 20px;
    width: 415px;

    @media (max-width: 600px) {
      max-width: 90%;
      padding: 30px 10px;
    }
  }

  .dialog-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #262626;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .dialog-subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 142.88%;
    color: #424242;
    text-align: center;
    margin: 24px auto 8px;
    width: 60%;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .dialog-footer {
    font-weight: 400;
    font-size: 16px;
    line-height: 142.88%;
    text-align: center;
    color: #424242;
    margin: 8px 0 16px;

    @media (max-width: 600px) {
      font-size: 14px;
      max-width: 70%;
      margin: 8px auto 16px;
    }
  }

  .dialog-image {
    margin: 0 auto;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }

  .close-icon-img {
    height: 16px;
    width: 16px;
  }
`;

export const DownloadAppModal = () => {
  const dispatch = useDispatch();
  const below600 = useViewport(600).below;

  const handleClose = () => {
    dispatch(toggleDownloadAppModal());
  };

  return (
    <DownloadAppModalWrapper open={true} onClose={handleClose}>
      <span className="close-icon">
        <img
          src={imageParser("new close icon.svg")}
          alt="Close icon"
          onClick={handleClose}
          className="close-icon-img"
        />
      </span>

      <Typography className="dialog-title">Get the Kenko App</Typography>

      <Typography className="dialog-subtitle">
        Scan this QR code to download the app now
      </Typography>

      <img
        src={imageParser("download_buttons/download_qr_code.png")}
        alt=""
        className="dialog-image"
        height={181}
        width={181}
      />

      <Typography className="dialog-footer">
        {below600
          ? "Download the app in Android/IOS from the app stores"
          : "or check it out in the app stores"}
      </Typography>

      <DownloadButtons />
    </DownloadAppModalWrapper>
  );
};

const DownloadAppWrapper = styled.div`
  & .MuiPaper-root {
    border-radius: 16px;
    padding: 40px 20px;
    width: 415px;

    @media (max-width: 600px) {
      max-width: 90%;
      padding: 30px 10px;
    }
  }

  .dialog-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #262626;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .dialog-subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 142.88%;
    color: #424242;
    text-align: center;
    margin: 24px auto 8px;
    width: 60%;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .dialog-footer {
    font-weight: 400;
    font-size: 16px;
    line-height: 142.88%;
    text-align: center;
    color: #424242;
    margin: 8px 0 16px;

    @media (max-width: 600px) {
      font-size: 14px;
      max-width: 70%;
      margin: 8px auto 16px;
    }
  }

  .dialog-image {
    margin: 0 auto;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 24px;
    cursor: pointer;
  }

  .close-icon-img {
    height: 16px;
    width: 16px;
  }
`;

export const DownloadApp = () => {
  const below600 = useViewport(600).below;

  return (
    <>
      <DownloadAppWrapper>
        <span className="close-icon">
          <img
            src={imageParser("new close icon.svg")}
            alt="Close icon"
            className="close-icon-img"
          />
        </span>

        <Typography className="dialog-title">Get the Kenko App</Typography>

        {/* <Typography className="dialog-subtitle">
          Scan this QR code to download the app now
        </Typography>

        <img
          src={imageParser("download_buttons/download_qr_code.png")}
          alt=""
          className="dialog-image"
          height={181}
          width={181}
        /> */}

        <Typography className="dialog-footer">
          {below600
            ? "Download the app in Android/IOS from the app stores"
            : "or check it out in the app stores"}
        </Typography>

        <DownloadButtons />
      </DownloadAppWrapper>
    </>
  );
};
