import { memo, useEffect } from "react";
import { Grid } from "@mui/material";

import Button from "../../../shared/components/Button/Button";
import Typography from "../../../shared/components/Typography/Typography";
import { heroActionBtn } from "./HeroSection.styles";
import { heroSectionSliderMobile } from "./HeroSection.styles";

import styled from "@emotion/styled";
import { imageParser } from "../../../shared/utils/image.utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Wrapper } from "../../../shared/components/Layout";

import { shouldRedirectToNewFlow } from "../../../shared/utils/helper.utils";
import tracker from "../../../shared/utils/tracker.utils";

import {
  HERO_SIGNUP_PAGE,
  KENKO_SCORE_PAGE,
  MY_PLANS_PAGE,
  PLAN_SELECTION,
  SIGNUP_PAGE,
} from "../../../shared/constants/routes";
import { OTP_VERIFIED } from "../../../shared/constants/enums";
import { useRouter } from "next/router";
import { useInView } from "react-intersection-observer";
import { backendEvents } from "../../../shared/constants/backend-events";
import { backendDrivenEvents } from "../../../slices/onboarding.slice";

const HeroSliderWrapper = styled(Wrapper)(heroSectionSliderMobile);
const HeroActionBtnWrapper = styled(Button)(heroActionBtn);

const HeroTextCaptionWrapper = styled(Grid)`
  text-align: center;
  font-family: "Figtree";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    margin: 0;
  }

  .info-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 12px;
    flex-wrap: wrap;
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #bed0a2;
  }

  .info-divider {
    width: 2px;
    background-color: #efedd8;
    margin: 5px 0;
  }

  .info-states {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  .info-text {
    font-size: 12px;
    font-weight: 400;
  }
`;

const MedicalBillWrapper = styled(Typography)(`
   color: var(--lightSprout);
   line-height: 38px;

   @media (max-width: 400px) { 
     font-size: 30px !important;
  }
`);

export const HeroSectionMobile = () => {
  const router = useRouter();
  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_HERO_SECTION,
        })
      );
    }
  }, [inView, dispatch]);

  const [loaded, setLoaded] = useState(false);

  const utm = useSelector((state: any) => state.utmReducer);
  const auth = useSelector((state: any) => state.auth);
  const newFlow = shouldRedirectToNewFlow(utm);

  const heroSectionItemsMobile = [
    {
      id: 1,
      content: (
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            style={{ fontSize: "24px", fontWeight: 300 }}
          >
            Turn your
          </Typography>

          <MedicalBillWrapper
            variant="h4"
            style={{ fontSize: "32px", fontWeight: 700, margin: "8px 0" }}
          >
            Medical Bills to Zero
          </MedicalBillWrapper>

          <Typography style={{ fontSize: "16px", fontWeight: 500 }}>
            Plans Starting From <b>{newFlow ? "Rs 6/day" : "Rs 30/day"}</b>
          </Typography>
        </div>
      ),
      image: (
        <img
          src={
            loaded
              ? imageParser("hero_section/hero_image_mobile.webp")
              : imageParser("hero_section/hero_image_mobile_small.webp")
          }
          alt="Kenko Healthcare - Innovating for Better Health"
          onLoad={() => {
            setLoaded(true);
          }}
        />
      ),
    },
  ];

  const handleSubscribeNow = () => {
    tracker.pushAll("web_buy_now", {
      Page_Url: window.location.href,
      Location: "HeroSection",
      platform_type: "mobile",
    });

    if (auth?.userInfo?.userId) {
      let url = newFlow ? PLAN_SELECTION : KENKO_SCORE_PAGE;
      if (
        auth?.onBoardingInfo?.userPlanOnboardingData[0]?.planDetails
          ?.userPlanStatus === "SUBSCRIBER" ||
        auth?.onBoardingInfo?.userPlanOnboardingData[0]?.planDetails
          ?.userPlanStatus === "PAID"
      )
        url = MY_PLANS_PAGE;

      router.push(url);
    } else {
      router.push(
        auth?.sendOTP?.phone && auth?.sendOTP?.status === OTP_VERIFIED
          ? SIGNUP_PAGE
          : HERO_SIGNUP_PAGE
      );
    }
  };

  return (
    <div ref={ref}>
      <HeroSliderWrapper className="hero-mobile-wrapper">
        <Wrapper className="hero-mobile-container">
          {heroSectionItemsMobile.map((item) => (
            <Grid container key={item.id} spacing={2}>
              <Grid item xs={12}>
                {item.content}
              </Grid>

              <Grid
                xs={12}
                style={{
                  padding: 0,
                  gap: "24px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <HeroActionBtnWrapper
                  label="Subscribe now"
                  variant="contained"
                  size="xl"
                  style={{
                    margin: "16px auto 8px",
                    color: "#202020",
                    fontFamily: "Figtree",
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "140%",
                    boxShadow: "unset",
                  }}
                  onClick={handleSubscribeNow}
                />
                <HeroTextCaptionWrapper>
                  <div className="info-wrapper">
                    <div className="info-container">
                      <p className="info-states">3 Lakh+</p>
                      <p className="info-text">Subscribers</p>
                    </div>
                    <div className="info-divider"></div>
                    <div className="info-container">
                      <p className="info-states">100 Crore+</p>
                      <p className="info-text">Worth of benefits settled</p>
                    </div>
                    <div className="info-divider"></div>
                    <div className="info-container">
                      <p className="info-states">9000+</p>
                      <p className="info-text">Network hospital</p>
                    </div>
                  </div>
                </HeroTextCaptionWrapper>
              </Grid>

              <Grid
                xs={12}
                style={{
                  marginTop: "27px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.image}
              </Grid>
            </Grid>
          ))}
        </Wrapper>
      </HeroSliderWrapper>
    </div>
  );
};

export default memo(HeroSectionMobile);
