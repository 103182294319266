export const pageContentDiabetes = {
  IMAGE: "articles/Diabetes-Plan-article.svg",
  CARD_IMAGE: "articles/Diabetes-Plan.svg",
  MOBILE_IMAGE: "articles/Diabetes-Plan-mobile.svg",
  BLUR_IMAGE: "articles/Diabetes-Plan-article-blur.png",
  IMAGE_ALT: "Manage your diabetes effectively with Kenko Diabetes Care Plan",
  TITLE: "Old-school Healthcare Plans Won't Suffice",
  SUB_TITLE: "Presenting A Dedicated Diabetes Plan For You",

  HERO_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          Not many people appreciate the importance of a good healthcare plan.
          Even if you take excellent care of yourself, you can be vulnerable to
          a range of health conditions. This, in turn, can affect your finances.
          Healthcare plans are a great way of looking after your body while
          efficiently handling the costs of medicines and hospitalisation.
          However, the situation gets far more complicated if you have diabetes.
        </p>
      ),
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          Diabetics often require extra care and attention, along with numerous
          other medical benefits not usually provided benefits by ordinary
          healthcare plans. Additionally, most healthcare plans do not kick in
          immediately and this can further complicate your financial condition.
          Therefore, it is essential to get a Diabetes Plan.
        </p>
      ),
    },
  ],

  CONTENT: [
    { ID: 1, SUBHEADING: "Diabetes — A Rapidly Growing Phenomenon" },
    {
      ID: 2,
      CONTENT: (
        <p>
          A study published by The Lancet, in 2014 revealed that out of the
          adults worldwide diagnosed with diabetes, 50% lived in India, China,
          Brazil, Indonesia and America. India alone contributed to 15.3% of the
          total global adult population with diabetes.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          With over 70 million diabetics in the country, India is often
          considered to be the global diabetes capital. As per estimations from
          the World Health Organisation (WHO), the total number of diabetics
          will increase by 45% and cross 101 million by 2030. The total number
          of Indians with diabetes has already shot up from 11.9 million in 1980
          to 64.5 million in 2015.
        </p>
      ),
    },
    {
      ID: 4,
      CONTENT: (
        <p>
          Long working hours, poor diet, and an increasingly stressful
          environment are taking a toll on Indians and their health. This rapid
          increase in patients has made diabetes the silent killer for Indians
          today.
        </p>
      ),
    },
    {
      ID: 5,
      SUBHEADING: "Age — A Defining Factor",
    },
    {
      ID: 6,
      CONTENT: (
        <p>
          The prevalence of diabetes in Indian men has increased from 3.7% to
          9.1%, while the same has changed from 4.6% to 8.3% in women. The risk
          grows even more as you grow older, with you becoming more vulnerable
          to Type 2 Diabetes with each passing year.
        </p>
      ),
    },
    {
      ID: 7,
      CONTENT: (
        <p>
          If you are older than 45 years of age, along with being overweight or
          having a family history of diabetes, it places you at a much higher
          risk of getting diabetes. An additional aspect to consider is that as
          time passes, so will the cost of a good healthcare plan. Starting
          today will help you enjoy healthcare plans starting at a lower
          premium.
        </p>
      ),
    },
    {
      ID: 8,
      SUBHEADING: "Getting The Right Diabetes Care Plan",
    },
    {
      ID: 9,
      CONTENT: (
        <p>
          Diabetes can redefine the way you live your life. Right from finding
          the best diet plan for diabetics to getting regular exercise, there
          are several steps to take, to safeguard your well-being. Getting a
          good diabetes care plan could be the penultimate step in this
          direction.
        </p>
      ),
    },
    {
      ID: 10,
      CONTENT: (
        <p>
          The right diabetes care plan goes above and beyond your regular
          benefits to provide the special care this inconvenient disease
          requires. Most healthcare plans do not include benefits for diabetes
          patients. Even the ones that do, the benefits do not kick in
          immediately; they usually come into effect 2 to 3 years after you
          invest in a healthcare plan, especially for conditions like diabetes.
        </p>
      ),
    },
    {
      ID: 11,
      CONTENT: (
        <p>
          It is pondered and concluded at Kenko that a diabetes health plan
          should be started immediately when or before diabetes is detected.
          This can help you to get on track immediately with a diabetes plan
          like a diabetic diet plan, exercises, regulating several habits, etc.
          The Kenko Diabetes Plan gives you immediate protection, with the
          benefits starting from day 1.
        </p>
      ),
    },
    {
      ID: 12,
      SUBHEADING: "Additional Expenses That Keep Building Up",
    },
    {
      ID: 13,
      CONTENT: (
        <p>
          Another key difference is that diabetes care plans are much more
          expensive than regular healthcare plans. Diabetes falls under the
          category of high-risk diseases, which in turn raises your healthcare
          plan costs.
        </p>
      ),
    },
    {
      ID: 14,
      CONTENT: (
        <p>
          As a diabetes patient, you may need a diet plan for diabetes patients
          and medical help on a regular basis. Regular treatment can include
          daily medication, insulin injections,{" "}
          <a
            href="https://www.blog.kenkohealth.in/post/you-need-a-health-checkup-and-it-s-free"
            target="_blank"
            rel="noreferrer"
          >
            monthly health check-ups
          </a>
          , blood check-ups, and doctor visits, among others like a healthy diet
          plan for diabetic patients.
        </p>
      ),
    },
    {
      ID: 15,
      CONTENT: (
        <p>
          Needless to say, these expenses can put a tremendous financial burden
          on you and your loved ones. The average expenses for a person with
          diabetes can range between Rs. 3,000/- to Rs. 8,000/- per month,
          amounting to Rs. 35,000/- to Rs. 75,000/- per annum. Medical
          emergencies and complications can further raise these costs
          significantly.
        </p>
      ),
    },
    {
      ID: 16,
      CONTENT: (
        <p>
          Furthermore, the constantly rising demand for insulin in India, an
          essential tool for every diabetic, is slowly making it unaffordable
          for a majority of patients in India. You might require a healthy diet
          plan for diabetic patients and a diabetes health plan solution that
          protects your well-being without burning a hole in your pocket.
        </p>
      ),
    },
    {
      ID: 17,
      SUBHEADING:
        "Kenko Diabetes Plan — Comprehensive Benefits Provided For Your Specific Needs",
    },
    {
      ID: 18,
      CONTENT: (
        <p>
          The Kenko Diabetes Plan has been tailor-made for diabetes patients.
          Prioritising comprehensive benefits, our focus is on ensuring that
          your financial expenses do not overwhelm you at any step of the way
          that you follow.
        </p>
      ),
    },
    {
      ID: 19,
      CONTENT: (
        <p>
          During your hospitalisation, you need a nursing care plan for diabetes
          management. We have observed that a proper diet plan for diabetes
          patients can keep them much more stable and healthier. So, a healthy
          diet plan for diabetics is an essential part to take care of.
        </p>
      ),
    },
    {
      ID: 20,
      CONTENT: (
        <p>So, what advantages does the Kenko Diabetes Plan provide to you?</p>
      ),
    },
    {
      ID: 21,
      CONTENT: <h3>OPD Savings Benefit</h3>,
    },
    {
      ID: 22,
      CONTENT: (
        <p>
          OPD benefits in traditional health plans fail to give benefits to
          diabetes-related costs. Additionally, the benefits of the plans may or
          may not kick in immediately, if they are not Kenko plans. Kenko works
          to provide you with an immediate 50% off on all{" "}
          <a
            href="https://kenkohealth.in/plan/299-Mini-Plan"
            target="_blank"
            rel="noreferrer"
          >
            OPD expenses
          </a>
          , with approximate savings of up to Rs. 20,000/- every month.
        </p>
      ),
    },
    {
      ID: 23,
      CONTENT: (
        <p>
          Our Kenko Diabetes Plan goes above and beyond other traditional plans
          to include the cost of insulin as well. As a person with diabetes, you
          get complete protection on all expenses, as well as constant savings.
          Thus, the diabetes health care plan at Kenko looks after all the
          patients’ expenses and saves them while the patients take their
          treatments.
        </p>
      ),
    },
    {
      ID: 24,
      CONTENT: <h3>Immediate Benefits And No Restrictions</h3>,
    },
    {
      ID: 25,
      CONTENT: (
        <p>
          When it comes to diabetes, health plans can make you wait several
          years before the benefits kick in as it is considered as a
          pre-existing condition. You need a nursing care plan for DM (diabetes
          management). You need a healthy diet plan for diabetics with
          supplements of vitamins, minerals and proteins in diets or in
          medicines.
        </p>
      ),
    },
    {
      ID: 26,
      CONTENT: (
        <p>
          When you{" "}
          <a
            href="https://kenkohealth.in/plans"
            target="_blank"
            rel="noreferrer"
          >
            purchase any healthcare plan
          </a>
          , make sure you check the additional time you may have to spend
          without any protection. Furthermore, providing benefits to
          pre-existing conditions, such as diabetes, can shoot up the cost of
          your healthcare plan, making you spend tens of thousands of rupees
          more every year.
        </p>
      ),
    },
    {
      ID: 27,
      CONTENT: (
        <p>
          With Kenko, you get a diabetes health plan whose benefits kick in
          immediately and have no restrictions. Thus, you get the medical care
          that provides you a nursing care plan for DM (Diabetes management)
          without going through any loopholes. These measures are available to
          you at a nominal cost of Rs. 999/- every month. You also get a
          complete understanding of the scope of benefits provided by the
          diabetes health plan.
        </p>
      ),
    },
    {
      ID: 28,
      CONTENT: (
        <p>
          We will provide you with savings on doctor visits, medicines, insulin
          shots, as well as the additional medical support you may require. With
          Kenko at your side, you can rest easy and focus on your diabetic diet
          plan.
        </p>
      ),
    },
    {
      ID: 29,
      CONTENT: <h3>Protection From Additional Illnesses</h3>,
    },
    {
      ID: 30,
      CONTENT: (
        <p>
          Diabetes often requires taking care of your health through measures
          such as exercising, and following a diabetic diet plan. Having a
          proper diet plan for diabetes patients can help you to some measure
          and avoid the effects of diabetes. However, even with the best
          efforts, you can be further diagnosed with diseases like blindness,
          kidney failure, nerve damage, and heart diseases. Regular check-ups
          can help safeguard against such challenges.
        </p>
      ),
    },
    {
      ID: 31,
      CONTENT: (
        <p>
          When unchecked, these issues can cause medical and financial
          emergencies. Having a healthy diet plan for diabetic patients with a
          nursing care plan for DM (Diabetes management) can ease some of the
          concerns. However, the Kenko Diabetes Plan offers you 50% off on
          doctor appointments, ensuring regular{" "}
          <a href="https://kenkohealth.in/" target="_blank" rel="noreferrer">
            healthcare
          </a>{" "}
          does not burn a hole in your pocket. As you grow older, so will the
          risk of such diseases due to diabetes, making regular check-ups by the
          diabetes healthcare plan all the more necessary. Kenko Diabetes
          healthcare plan makes these check-ups affordable, protecting your
          health and your savings.
        </p>
      ),
    },
    {
      ID: 32,
      CONTENT: <h3>Low-cost Healthcare Plan</h3>,
    },
    {
      ID: 33,
      CONTENT: (
        <p>
          As diabetes is a long-term disease, you are prone to use the benefits
          about 90% more than most other ailments. Men, in particular, are more
          affected than women when it comes to this disease, with the
          probability of its onset increasing as you get older. As you grow
          older, so can the cost of comprehensive healthcare plans.
        </p>
      ),
    },
    {
      ID: 34,
      CONTENT: (
        <p>
          The Kenko Diabetes Plan provides you with exceptional healthcare at
          just Rs. 999/- a month. Furthermore, the diabetes healthcare plan
          provides you with savings on doctor's visits, medical tests and
          medicines, you receive savings every day of the year if you choose
          Kenko’s diabetes healthcare plan.
        </p>
      ),
    },
    {
      ID: 35,
      CONTENT: <h3>Savings Beyond Hospitalisation</h3>,
    },
    {
      ID: 36,
      CONTENT: (
        <p>
          Traditional healthcare providers only focus on protecting your
          financial health during extreme medical emergencies that require
          hospitalisation or similar treatment. However, there are several
          monthly health expenses, such as vitamins and diabetes medicines, that
          can actually pile up over time. By paying for these expenses from your
          own pocket, you face a slow yet growing financial and healthcare
          burden.
        </p>
      ),
    },
    {
      ID: 37,
      CONTENT: (
        <p>
          We at Kenko think about you and your expenses and address these issues
          by giving you 50% off on all the medicines and{" "}
          <a
            href="https://kenkohealth.in/plan/599-Individual-Plan"
            target="_blank"
            rel="noreferrer"
          >
            lab tests
          </a>{" "}
          that your doctor suggests, and that you require. From daily
          supplements to nursing care plan for DM (Diabetes management), and
          periodic blood tests, you save on every expense you undertake to
          maintain your health. With our Diabetes Health Plan at Kenko, taking
          care of your health is truly rewarding for you in every possible way,
          be it a diabetes care plan or a diabetic diet plan.
        </p>
      ),
    },
    {
      ID: 38,
      CONTENT: <h3>Better Healthcare Today, A Better Life Tomorrow</h3>,
    },
    {
      ID: 39,
      CONTENT: (
        <p>
          As a diabetes patient, you need to take a few extra precautions like
          following a strict diabetic diet plan and having a healthy diet plan
          for diabetic patients to ensure that this disease does not define how
          you live your life. At every step from now onwards, you need a nursing
          care plan for diabetes management and a diabetic diet plan. Having a
          diet plan for diabetes patients and an insurtech companion who
          supports you at every step of the way in this journey is just as
          crucial. With Kenko, you enjoy comprehensive diabetes healthcare plan
          benefits provided, as well as savings on those little expenses that
          keep popping up. What more could you ask for?
        </p>
      ),
    },
  ],

  ENDING_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          Safeguard your health for your loved ones today and live a stress-free
          and financial burden-free life with Kenko who will take care of all
          your healthcare planning.
        </p>
      ),
    },
  ],

  CTA_BUTTON: "Get Your Subscription Now",
  ROUTE: "/kenko-score",
};
