import styled from "@emotion/styled";
import { imageParser } from "../../shared/utils/image.utils";

const DownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .app-link {
    text-decoration: none;
  }

  .app-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
  }

  .app-image {
    width: 176px;
    height: auto;

    @media (max-width: 600px) {
      width: 130px;
    }
  }
`;

const appLinks = {
  playStoreLink:
    "https://play.google.com/store/apps/details?id=com.redkenko.health",
  appStoreLink:
    "https://apps.apple.com/in/app/kenko-all-in-one-health-plans/id1616791973",
};

export const DownloadButtons = () => {
  return (
    <DownloadButtonWrapper>
      <a
        href={appLinks.playStoreLink}
        className="app-link"
        target="_blank"
        rel="noreferrer"
      >
        <button
          className="app-button"
          aria-label="Download Kenko App from Playstore"
        >
          <img
            className="app-image"
            src={imageParser("download_buttons/button_play_store.webp")}
            alt="Get kenko app on google play store"
          />
        </button>
      </a>

      <a
        href={appLinks.appStoreLink}
        className="app-link"
        target="_blank"
        rel="noreferrer"
      >
        <button
          className="app-button"
          aria-label="Download Kenko App from App Store"
        >
          <img
            className="app-image"
            src={imageParser("download_buttons/button_app_store.webp")}
            alt="Get kenko app on apple store"
          />
        </button>
      </a>
    </DownloadButtonWrapper>
  );
};
