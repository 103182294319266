import React, { useEffect } from "react";
import HospitalList from "../HospitalNetwork/HospitalsList";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { backendDrivenEvents } from "../../slices/onboarding.slice";
import { backendEvents } from "../../shared/constants/backend-events";

export const HospitalSection = () => {
  const { ref: hospitalSectionRef, inView: hospitalSectionInView } =
    useInView();
  const appDispatch = useDispatch();

  useEffect(() => {
    if (hospitalSectionInView) {
      appDispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_HOSPITAL_NETWORK,
        })
      );
    }
  }, [hospitalSectionInView, appDispatch]);

  return (
    <div ref={hospitalSectionRef}>
      <p className="hospital-title">Our Hospital Network </p>
      <HospitalList />
    </div>
  );
};
