export const pageContent599 = {
  IMAGE: "articles/599-Individual-Plan-article.svg",
  CARD_IMAGE: "articles/599-Individual-Plan.svg",
  MOBILE_IMAGE: "articles/599-Individual-Plan-mobile.svg",
  BLUR_IMAGE: "articles/599-Individual-Plan-article-blur.png",
  IMAGE_ALT: "Online Medicine Delivery for individual needs- Kenko",
  TITLE: "Want Online Medicine Delivery At A Discount?",
  SUB_TITLE: "Get The Kenko 599 Individual Plan",

  HERO_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          India’s medical inflation at 14% is the highest among all Asian
          countries. Worried you may have to choose between your health and
          finances?
        </p>
      ),
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          Traditional healthcare plans are simply not flexible enough to meet
          the new-age requirements. They may take care of your hospitalisation
          expenses, but you want your{" "}
          <a
            href="https://kenkohealth.in/plans"
            target="_blank"
            rel="noreferrer"
          >
            healthcare plan
          </a>{" "}
          to give you discounts even on your OPD bills: You would want a plan
          that gives you discounts on your online medicine orders, doctor
          consults, and lab test bookings.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          You do not have to resign yourself to using any imperfect individual
          plans. You could subscribe to Individual Healthcare Plans by Kenko.
        </p>
      ),
    },
    {
      ID: 4,
      CONTENT: (
        <p>
          They don’t just give discounts on online medicine orders, doctor
          consults, and lab test bookings. You also get access to an exclusive
          members-only app that helps you place and track medicine orders, book
          lab tests, and get online doctor consults, all from the comforts of
          your home.
        </p>
      ),
    },
    {
      ID: 5,
      CONTENT: (
        <p>
          Online medicine orders and lab test bookings aren’t the only
          advantages of the Kenko 599 Individual Plan. Here’s a list of all the
          reasons why you should start your healthcare journey with Kenko as
          your preferred choice for Individual Plans.
        </p>
      ),
    },
  ],

  CONTENT: [
    { ID: 1, SUBHEADING: "Buy Medicines Online With Discounts" },
    {
      ID: 2,
      CONTENT: (
        <p>
          We’ve already established that medical inflation is on the rise in
          India. Thus, not just specialised medicines, but even the regular
          vitamins and daily supplements that people depend on are getting
          increasingly unaffordable.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          Thankfully, the Kenko 599 Individual Plan strikes right at the heart
          of this issue by offering 50% off with all online medicine orders.
          Thus, with the 599 Individual Plan, you save money on the medication
          you require with online medicine orders, and you also get medicine
          home delivery - right to your doorstep.
        </p>
      ),
    },
    {
      ID: 4,
      CONTENT: (
        <p>
          Be it a small strip of painkillers or potent medicines that fight
          life-threatening illnesses, Kenko Individual Health Plans ensure that
          you can easily buy medicines online with discounts of up to 50%.
        </p>
      ),
    },
    {
      ID: 5,
      SUBHEADING: "Book Diagnostic Tests Online With Discounts",
    },
    {
      ID: 6,
      CONTENT: (
        <p>
          Preventive care has been increasingly gaining popularity. One
          important aspect of preventive care is to get lab tests, preferably
          done from a good diagnostics laboratory. From CT scans to
          comprehensive blood tests, when you search, you will find a plethora
          of lab tests online that can help you get to the root cause of your
          illnesses or potential threats.
        </p>
      ),
    },
    {
      ID: 7,
      CONTENT: (
        <p>
          However, these lab tests can cost thousands of rupees and burn a hole
          in your pocket. Also, since the pandemic, we’ve all grown accustomed
          to getting things home-delivered.
        </p>
      ),
    },
    {
      ID: 8,
      CONTENT: (
        <p>
          We already mentioned that Kenko’s Individual Health Plans help you buy
          medicines online with discounts while you get medicine home delivery,
          so there is no reason why you can’t book lab tests online as well.
        </p>
      ),
    },
    {
      ID: 9,
      CONTENT: (
        <p>
          The Kenko 599 Individual Plan helps you book lab tests online. We have
          partnered with the best diagnostic laboratories in the country with
          state of the art facilities. Kenko also offers home sample collection
          for your online lab test bookings.
        </p>
      ),
    },
    {
      ID: 10,
      CONTENT: (
        <p>
          Wondering whether these regular lab test bookings from expensive
          diagnostic laboratories for preventive care are actually worth it? You
          can read our article on{" "}
          <a
            href="https://www.blog.kenkohealth.in/post/why-regular-health-checkups-make-financial-sense"
            target={"_blank"}
            rel="noreferrer"
            className="link"
          >
            how it makes financial sense
          </a>
          .
        </p>
      ),
    },
    { ID: 11, CONTENT: <h2>Access The Best Healthcare Facilities</h2> },
    {
      ID: 12,
      CONTENT: (
        <p>
          Occasionally, we would want to go for a cheaper option instead of a
          state-of-the-art diagnostic laboratory. Or you may choose to visit
          your local doctor when you actually need a specialist. The primary
          reason to choose an undercut{" "}
          <a href="https://kenkohealth.in/" target="_blank" rel="noreferrer">
            healthcare
          </a>{" "}
          facility is the financial fear of burning holes in your pocket.
        </p>
      ),
    },
    {
      ID: 13,
      CONTENT: (
        <p>
          What we don’t realise is that a subpar assessment by an underfunded
          diagnostic laboratory can undermine your entire objective of
          preventive care. Or a delayed online medicine order can seriously
          hamper your health.
        </p>
      ),
    },
    {
      ID: 14,
      CONTENT: (
        <p>
          This is where Kenko’s Individual Health Plans can help you. Once you
          subscribe to individual healthcare plans, you can access the best
          diagnostic laboratories and hospitals. Additionally, we have a wide
          network of doctors for you.
        </p>
      ),
    },
    {
      ID: 15,
      CONTENT: (
        <p>
          At Kenko, we have invested a tremendous amount of time and effort in
          establishing a network of doctors at the top of their game, who would
          be easily accessible to you. We take extra care to cover lesser-known
          illnesses, niche specialisations, and a range of experts that span
          gender, geographical regions, and other external factors.
        </p>
      ),
    },
    {
      ID: 16,
      CONTENT: (
        <p>
          To sum up our healthcare facilities, we do medicine home delivery
          within 6-24 hours of order confirmation. You can book diagnostic tests
          online and we will give you a home sample collection. And if you want
          a doctor consultation, you just need to login to the app and choose
          your preferred doctor for an online consultation.
        </p>
      ),
    },
    {
      ID: 17,
      CONTENT: <p>And remember, all of this is at a discounted price.</p>,
    },
    {
      ID: 18,
      SUBHEADING: "Make All OPD Bills ZERO",
    },
    {
      ID: 19,
      CONTENT: (
        <p>
          Traditional healthcare plans for individuals were designed to only
          give you hospitalisation benefits. They did not take care of your OPD
          bills, let alone vision care, mental care, dental care and so on.
        </p>
      ),
    },
    {
      ID: 20,
      CONTENT: (
        <p>
          According to these traditional plans, you would have to shell out
          money from your own pocket if you wanted to get a simple eye check up.
          Subscribe to Kenko Health Care Plans for individuals and get discounts
          on it.
        </p>
      ),
    },
    {
      ID: 21,
      CONTENT: (
        <p>
          Also, we don’t just do online medicine delivery, we even deliver your
          daily healthcare items to your doorstep. From toothpaste to shampoo,
          from protein supplements to personal hygiene products, we have it all
          on Kenko Marketplace: Shoho.
        </p>
      ),
    },
    {
      ID: 22,
      SUBHEADING: "Why An Individual Plan?",
    },
    {
      ID: 23,
      CONTENT: (
        <p>
          Wondering why you should go for an individual health plan? Kenko’s
          individual healthcare plan understands the needs of individuals and is
          designed so that you don’t have to run from pillar to post when you’re
          recovering from an illness.
        </p>
      ),
    },
    {
      ID: 24,
      CONTENT: (
        <p>
          This plan is a must-have for you if you’re staying alone. Sometimes,
          your local pharmacy medicine delivery may be running late or are
          understaffed when you need medicines. Just when you find the right
          pharmacy medicine delivery which isn’t understaffed and which will
          deliver on time, you realise that they may not have the medicine in
          stock.
        </p>
      ),
    },
    {
      ID: 25,
      CONTENT: (
        <p>
          With Kenko, you get online medicine home delivery. Not just doorstep
          delivery, you can buy medicines online with discounts. Did the doctor
          ask you to Feel get a lab test but can’t reach it? Book lab tests
          online and let us handle the logistics.
        </p>
      ),
    },
    {
      ID: 26,
      CONTENT: (
        <p>
          So, stay at home, put your feet up, and let your savings and medicines
          come to you through the Kenko app’s online medicine delivery feature.
        </p>
      ),
    },
    {
      ID: 27,
      SUBHEADING: "Enter The New-Age Solutions In An Insurtech World",
    },
    {
      ID: 28,
      CONTENT: (
        <p>
          The demands of a faster pace of life dictate that most patients can no
          longer wait for weeks and months to receive the medical support they
          require. Insurtech companies like Kenko are designed for this evolved
          world, crafted to provide immediate assistance with features like
          online medicine delivery and lab test booking online.
        </p>
      ),
    },
    {
      ID: 29,
      CONTENT: (
        <p>
          With consistent service support and comprehensive care packages to
          meet every person’s needs, Kenko curates its plans for different
          requirements.
        </p>
      ),
    },
    {
      ID: 30,
      CONTENT: (
        <p>
          What we take immense pride in is our ability to help you save on those
          monthly expenses that keep piling on when ignored - regular medicine
          orders, periodic check-ups, and lab test bookings.
        </p>
      ),
    },
    {
      ID: 31,
      CONTENT: (
        <p>
          Kenko offers you a modern solution that enables you to protect your
          finances while taking care of your health without any hesitations.
          With uncomplicated plans to choose from, you no longer need to spend
          weeks or months trying to find the right individual healthcare plan.
          It’s right here, in front of you, with Kenko.
        </p>
      ),
    },
    {
      ID: 32,
      CONTENT: (
        <p>
          So, opt for the Kenko 599 Individual Plan and enjoy savings on all
          your health expenses. To reiterate, you will get online medicine
          delivery, lab test discounts, lab test online bookings and much more.
        </p>
      ),
    },
  ],

  ENDING_CONTENT: [
    {
      ID: 1,
      CONTENT: <p>So what are you waiting for? Subscribe now!</p>,
    },
  ],

  CTA_BUTTON: "Get Your Subscription",
  ROUTE: "/kenko-score",
};
