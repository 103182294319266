import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const PaperWrapper = styled.div<any>`
  .paper-container {
    height: 100%;
    width: 100%;
  }

  .paper-container.paper-rounded {
    border-radius: 12px;
  }

  .paper-container.paper-0 {
    box-shadow: unset;
  }

  .paper-container.paper-1 {
    box-shadow: var(--gray01dp);
  }

  .paper-container.paper-2 {
    box-shadow: var(--gray02dp);
  }

  .paper-container.paper-3 {
    box-shadow: var(--gray03dp);
  }

  .paper-container.paper-4 {
    box-shadow: var(--gray04dp);
  }

  .paper-container.paper-5:hover {
    box-shadow: 8px 8px 0px 0px #003032;
  }

  .paper-container.paper-6 {
    box-shadow: var(--gray06dp);
  }

  .paper-container.paper-8 {
    box-shadow: var(--gray08dp);
  }

  .paper-container.paper-12 {
    box-shadow: var(--gray12dp);
  }

  .paper-container.paper-16 {
    box-shadow: var(--gray16dp);
  }

  .paper-container.paper-24 {
    box-shadow: var(--gray24dp);
  }
`;

export default function Paper({
  elevation,
  rounded,
  style,
  className,
  children,
}: any) {
  return (
    <PaperWrapper>
      <div
        className={[
          "paper-container",
          `paper-${elevation}`,
          rounded && "paper-rounded",
          className,
        ].join(" ")}
        style={style}
      >
        {children}
      </div>
    </PaperWrapper>
  );
}

Paper.defaultProps = {
  elevation: 1,
};
