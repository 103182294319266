export const homePageContent = {
  CARD_IMAGE: "articles/seo-blog.png",
  IMAGE: "articles/homepage-article-min.png",
  MOBILE_IMAGE: "articles/seo-blog.png",
  BLUR_IMAGE: "articles/homepage-article-min.png",
  IMAGE_ALT:
    "Revolutionize Your Health with Kenko Healthcare in the Digital Age",
  TITLE: "Insurtech And Future",
  SUB_TITLE: "A New-Age Healthcare Revolution For You!",

  HERO_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          Wondering what insurtech is? It is when a healthcare provider
          facilitates their products with new-age tech. Read on and learn more!
        </p>
      ),
    },

    {
      ID: 2,
      CONTENT: (
        <p>
          The pandemic triggered a massive overnight transformation for
          healthcare companies, leading to the arrival of several new-age
          healthcare Indian players that approached the sector differently.
        </p>
      ),
    },
  ],

  CONTENT: [
    {
      ID: 38,
      CONTENT: (
        <p>
          Previously, when it came to healthcare, Indians had to suffer through
          longer processing times, endless paperwork, and a hostile attitude
          towards the customers.
        </p>
      ),
    },
    {
      ID: 39,
      CONTENT: (
        <p>
          At present, with healthcare, Indians have taken things digital. We no
          longer follow these norms. The health care plans have a new game
          changer; they now come with less or no paper work. Additionally, many
          health care plans now start your benefits the moment you subscribe.
        </p>
      ),
    },

    {
      ID: 40,
      CONTENT: (
        <p>
          Insurtech companies changed the perception of healthcare in India. As
          the world returns to its old routines, these new players are here to
          stay, replacing the previous complicated and partial norms.
        </p>
      ),
    },
    { ID: 1, SUBHEADING: "Kenko’s Evolution" },

    {
      ID: 2,
      CONTENT: (
        <p>
          A healthcare plan is a must if you don’t want to shell out a ton of
          money from your pockets for hospital treatment, especially during
          emergencies. The{" "}
          <a
            href="https://kenkohealth.in/login"
            target="_blank"
            rel="noreferrer"
          >
            healthcare subscriptions
          </a>{" "}
          that you pay act as your investment and take care of your medical
          expenses.
        </p>
      ),
    },

    {
      ID: 3,
      CONTENT: (
        <p>
          When it came to healthcare, Indians had to suffer through plans with
          jargon-filled, long paperwork. And still, they would have to ask for
          reimbursements. It wasn’t truly cashless, and you had to jump through
          numerous loops. This proved tiresome and confusing to many
          subscribers.
        </p>
      ),
    },

    {
      ID: 4,
      CONTENT: (
        <p>
          And even that didn’t guarantee that you would get your money back.
          Maybe you got a part of it. You may still have to pay a considerable
          sum from your own savings. Well, at least that’s how traditional
          health care plans worked.
        </p>
      ),
    },

    {
      ID: 5,
      CONTENT: (
        <p>
          At Kenko Health, we have worked on providing answers to these
          problems. Our health care plans address the shortcomings of age-old
          health care plans.
        </p>
      ),
    },

    {
      ID: 6,
      CONTENT: (
        <p>
          Our aim is to create a better environment for you that supports you
          constantly through all of life’s ups and downs through preventive
          healthcare plans. We work towards this goal by giving you monthly
          subscriptions that are designed after a thorough study and planned
          process. And what’s better, they are easy-to-renew healthcare plans.
        </p>
      ),
    },

    {
      ID: 7,
      SUBHEADING:
        "Benefits Of Kenko Healthcare Plan: With You In Sickness And Health",
    },

    {
      ID: 8,
      CONTENT: (
        <p>
          At Kenko Health, we believe that the right healthcare plan for you
          won’t just help you save money when you are hospitalised but also
          provide you with a holistic lifestyle. Regular medical expenses can
          pile up over a number of years. We strive to ensure that they don’t.
        </p>
      ),
    },

    {
      ID: 9,
      CONTENT: (
        <p>
          All you need to ensure is to renew healthcare plans regularly.{" "}
          <a
            href="https://kenkohealth.in/plans"
            target="_blank"
            rel="noreferrer"
          >
            Kenko Health Plans
          </a>{" "}
          will take care of the rest. We will even take care of the preventive
          health care expenses as well. We offer discounts on all your OPD
          bills, such as doctor’s consultations, medicines,{" "}
          <a
            href="https://kenkohealth.in/plan/599-Individual-Plan"
            target="_blank"
            rel="noreferrer"
          >
            lab tests
          </a>
          , and so on. Because of our extensive partnerships, we can service
          even the remotest locations.
        </p>
      ),
    },

    {
      ID: 10,
      CONTENT: (
        <p>
          There are numerous Kenko Health Plans you can choose from. Already
          subscribed to a Kenko Healthcare Plan? It is easy to even renew
          healthcare plans.
        </p>
      ),
    },

    { ID: 11, CONTENT: <h3>1. HealthCare Plan Affordable For All</h3> },

    {
      ID: 12,
      CONTENT: (
        <p>
          Health care plans can get rather expensive, with plans potentially
          costing tens of thousands of rupees each year. We believe that medical
          care should be a right for everyone, which is why our plans start at
          as little as Rs. 299/- every month. Thus, everyone can afford low-cost
          health care plans.
        </p>
      ),
    },

    {
      ID: 13,
      CONTENT: (
        <p>
          Because of our plans, many people now even consider preventive health
          care to be an integral part of their lives. They’ve saved a ton of
          money on preventive health care, doctor consults, lab tests, medicines
          and much more. Plus, you can upgrade or renew healthcare plans from
          the comforts of your home with just a few clicks.
        </p>
      ),
    },

    {
      ID: 14,
      CONTENT: (
        <p>
          Do you have elders you need to take care of? Health care plans can be
          particularly expensive for them. If you already have a Kenko Health
          Plan, we offer a{" "}
          <a
            href="https://kenkohealth.in/plans/Senior-Citizen-Plan"
            target="_blank"
            rel="noreferrer"
          >
            Senior Citizen Plan
          </a>{" "}
          for your elderly loved ones at an extremely low cost. Starting a
          family, you can consider our{" "}
          <a
            href="https://kenkohealth.in/plan/999-Family-Plan"
            target="_blank"
            rel="noreferrer"
          >
            Family Plan
          </a>
          .
        </p>
      ),
    },

    {
      ID: 15,
      CONTENT: (
        <p>
          We’re here to ensure you can care for your loved ones while
          safeguarding your finances.
        </p>
      ),
    },

    {
      ID: 16,
      CONTENT: (
        <h3>2. Collaborations With Other Revolutionary Game-changers</h3>
      ),
    },

    {
      ID: 17,
      CONTENT: (
        <p>
          In India, healthcare is a complex field, and so we believe in
          protecting you as comprehensively as possible. Thus, we have
          collaborated with other game-changers in this field. For example, we
          have partnered with Tata 1mg, India’s most trusted healthcare online
          pharmacy, to get your medicines delivered right to your doorstep at a
          discounted rate.
        </p>
      ),
    },

    {
      ID: 18,
      CONTENT: (
        <p>
          We have also built a vast network of doctors and hospitals across the
          country to support you whenever and wherever you need them, bringing
          your healthcare online.
        </p>
      ),
    },

    {
      ID: 19,
      CONTENT: (
        <p>
          Because we see an increased demand for online health consultations, we
          connect you to medical experts in various domains while you stay right
          at home. Our Kenko Healthcare online partnerships have helped us serve
          you better and give you the care you deserve.
        </p>
      ),
    },

    { ID: 20, CONTENT: <h3>3. Breaking Away From Traditional Plans</h3> },

    {
      ID: 21,
      CONTENT: (
        <p>
          Even with the best intentions, a traditional healthcare provider in
          India is often bogged down by conventional practices and old-school
          thinking. Rather than serving customers, there is a notion that
          healthcare plan holders must wrestle with agencies to avail their
          deserved benefits.{" "}
        </p>
      ),
    },

    {
      ID: 22,
      CONTENT: (
        <p>
          It is an archaic system and an exploitative one. One that needs to
          change to suit both pockets and form a medical protection shield
          against expensive hospital treatment.
        </p>
      ),
    },

    {
      ID: 23,
      CONTENT: (
        <p>
          The entry of new players like Kenko Healthcare has forced traditional
          healthcare providers to change. There is a greater onus on digitising
          processes today, but the established players are struggling to match
          the tech-based solutions that we provide.
        </p>
      ),
    },

    {
      ID: 24,
      CONTENT: (
        <p>
          As a way of expressing our belief in making healthcare accessible to
          you, we are cutting down on paperwork and unnecessary jargon. We keep
          our offerings simple and clear so that you know exactly what you’re
          getting when you join the Kenko health family. No trickery, no
          mischief. Just better care for you.
        </p>
      ),
    },

    { ID: 25, CONTENT: <h3>4. Numerous Plans Tailor-made For Your Needs</h3> },

    {
      ID: 26,
      CONTENT: (
        <p>
          As we mentioned earlier, the usual agency often has several healthcare
          packages and subpackages, with a mix of optional add-ons and hidden
          expenses. This makes selecting the right healthcare plan a tricky
          process for you. You must consider Kenko before you renew healthcare
          plan.
        </p>
      ),
    },

    {
      ID: 27,
      CONTENT: (
        <p>
          Furthermore, the complicated documents often leave people confused
          about the benefits these plans offer. At Kenko Health, our plans are
          designed to break away from this system.
        </p>
      ),
    },

    {
      ID: 28,
      CONTENT: (
        <p>
          With our tailor-made health care plans, our focus is on making the
          process of selecting your subscription plan extremely easy. Plus,
          these select health care plans address all the needs you may have.
        </p>
      ),
    },

    {
      ID: 29,
      CONTENT: (
        <p>
          Do you think that you would benefit from a dedicated{" "}
          <a
            href="https://kenkohealth.in/plan/Diabetes-Plan"
            target="_blank"
            rel="noreferrer"
          >
            Diabetes plan
          </a>
          ? We have one in place for you. Kenko Health wishes to extend your
          benefits to your entire family. We have the Kenko Family Plan for you,
          one solution that includes all your family’s needs. Simple, direct,
          and jargon-free.
        </p>
      ),
    },

    { ID: 30, CONTENT: <h3>5. Online Consultations Anytime, Anywhere</h3> },

    {
      ID: 31,
      CONTENT: (
        <p>
          We’ve tried our best to take healthcare online. You no longer need to
          restrict yourself to medical services only in your city. Get the best
          of healthcare while travelling to other places too. Renew healthcare
          plans from the comfort of your home or while you’re on vacation at no
          extra expense.
        </p>
      ),
    },

    {
      ID: 32,
      CONTENT: (
        <p>
          Through our Kenko healthcare partnerships, you can now consult some of
          India’s leading medical experts, all from the convenience of your
          home. This includes a range of specialists and some of the country’s
          most celebrated practitioners.
        </p>
      ),
    },

    {
      ID: 33,
      CONTENT: (
        <p>
          What’s more? No matter what your requirements may be, you can consult
          our medical experts without having to worry about the financial
          impact; as mentioned, we’ve truly taken healthcare online, and that
          too, free of cost. Step beyond your neighbourhood and enjoy a world of
          convenience and savings only with Kenko Health.
        </p>
      ),
    },

    { ID: 34, CONTENT: <h3>6. Quick Buy</h3> },

    {
      ID: 35,
      CONTENT: (
        <p>
          As a way of cutting down on the processing time, we have also created
          a quick booking process for you. Once you have identified the
          healthcare plan of your choice, all you need to do is get your Kenko
          Health score, an objective rating of your health. As soon as you
          receive your{" "}
          <a
            href="https://www.blog.kenkohealth.in/post/kenko-score-launch"
            target="_blank"
            rel="noreferrer"
          >
            Kenko Health score
          </a>
          , you can opt for the Kenko Health plan of your choice!
        </p>
      ),
    },

    {
      ID: 36,
      CONTENT: (
        <p>
          Plus, with the support of a comprehensive app and website, we’re with
          you every step of this very short onboarding process. At Kenko, your
          healthcare is online, we are also constantly available to address all
          your healthcare queries and questions so that you can make an informed
          decision. At Kenko Health, we believe the power lies with you and
          serves you well, either through healthcare online or in person.
        </p>
      ),
    },

    {
      ID: 37,
      CONTENT: (
        <p>
          Not just buying! It's easy to even renew healthcare plans from Kenko.
          One single click on the app takes you to the payment gateway. Renew
          healthcare plans before it expires to enjoy uninterrupted services.
        </p>
      ),
    },
  ],

  ENDING_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          As an insurtech company, keeping up with change is a part of our
          routine. We spend our time focusing on real problems and improving
          your core experience of accessing healthcare in India. This helps us
          at Kenko Health to stay ahead of the curve.
        </p>
      ),
    },

    {
      ID: 2,
      CONTENT: (
        <p>
          There are more than enough reasons for Kenko to secure your health and
          future. The solution lies in your answer.
        </p>
      ),
    },
  ],

  CTA_BUTTON: "View Our Plans",
  ROUTE: "/plans",
};
