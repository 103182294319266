import React, { useEffect, useMemo } from "react";
import dynamic from "next/dynamic";
import { useSelector } from "react-redux";

import { useViewport } from "./../../shared/hooks/useViewport";

import useRouter from "./../../shared/hooks/useRouter";

import { HeroSection } from "./HeroSection/HeroSection";
import { HeroSectionMobile } from "./HeroSection/HeroSectionMobile";

// const KenkoSteps = dynamic(
//   () =>
//     import("./KenkoStepsSection/KenkoSteps").then((mod: any) => mod.KenkoSteps),
//   {}
// );

const ParallaxWrapper = dynamic(
  () =>
    import("./Parallax/ParallaxWrapper").then(
      (mod: any) => mod.ParallaxWrapper
    ),
  {}
);

// const YouTubeSection = dynamic(
//   () => import("./YouTubeSection/YouTubeSection"),
//   { ssr: false }
// );

const TestimonialSection = dynamic(
  () => import("./TestimonialSection/TestimonialSection")
);

// const CoverageSection = dynamic(
//   () => import("./CoverageSection/CoverageSection")
// );
const BlogSectionMobile = dynamic(
  () => import("./BlogSection/BlogSectionMobile")
);
// const HospitalList = dynamic(() => import("../HospitalNetwork/HospitalsList"));
const BlogSection = dynamic(() => import("./BlogSection/BlogSection"));
const PlansSection = dynamic(() => import("./PlansSection/PlansSection"));
// const PressSection = dynamic(() => import("./news/PressSection"));
const ContactSection = dynamic(() => import("./ContactSection/ContactSection"));
const TrustedBySection = dynamic(() => import("./Investor/TrustedBySection"));
const FAQSection = dynamic(() => import("./FAQSection/FAQSection"));

import { SectionWrapper } from "../../shared/components/Layout";
import {
  kenkoDeepTeal,
  kenkoPureWhite,
  lightBg,
  mossGreen50,
} from "../../shared/assets/colors";

// import { PrevArrow, NextArrow } from "../../shared/components/Carousel";
import Article from "../Article/Article";
import { DownloadAppModal } from "../common/DownloadAppModal";
import { shouldRedirectToNewFlow } from "../../shared/utils/helper.utils";
import { NewPlansSection } from "./PlansSection/NewPlansSection";
import { detectMobileApp } from "../Onboarding/onboarding.helper";

import tracker from "./../../shared/utils/tracker.utils";
import { events } from "./../../shared/constants";
import { useAppDispatch } from "../../store";
import { backendDrivenEvents } from "../../slices/onboarding.slice";
import { backendEvents } from "../../shared/constants/backend-events";
// import { imageParser } from "../../shared/utils/image.utils";
// import { BenefitCard } from "../../shared/components/Layout/SectionWrapper";
// import {
//   KenkoVerseSection,
//   KenkoVerseTitle,
// } from "./KenkoVerseSection/KenkoVerseSection";
import styled from "@emotion/styled";
// import { benefitsArray } from "../../shared/constants/home";
// import { useInView } from "react-intersection-observer";
import { HospitalSection } from "./HospitalSection";
import { BenefitSection } from "./BenefitSection";
import { KenkoVerse } from "./KenkoVerse";

// padding
export const desktopPadding = "5vw";
export const mobilePadding = "5vw";

function HomePage({ plans }: any) {
  const router = useRouter();
  // const matchesSM = useViewport(900).below;
  const matchesXS = useViewport(600).below;
  // const slickMatchOne = useViewport(1000).below;

  const utm = useSelector((state: any) => state.utmReducer);
  const newFlow = shouldRedirectToNewFlow(utm);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const idElm = router.asPath.slice(1);
    document.getElementById(idElm)?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (router.asPath === "/#testimonial") {
      window.scrollTo({ top: matchesXS ? 3000 : 4400 });
    }
  }, [router.asPath, matchesXS]);

  const { showDownloadAppModal, platform } = useSelector(
    (state: any) => state.onboarding
  );

  const isMobileApp = detectMobileApp(platform);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: matchesXS ? 1 : matchesSM ? 2 : slickMatchOne ? 2 : 3,
  //   slidesToScroll: 1,
  //   swipeToSlide: true,
  //   focusOnSelect: true,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  // };

  useEffect(() => {
    tracker.pushWeb(events.HOMEPAGE_VIEW);
  }, []);

  useMemo(() => {
    dispatch(
      backendDrivenEvents({
        ...backendEvents.HOMEPAGE_VIEW,
      })
    );
  }, []);

  const HospitalWrapper = styled.div`
    background: "#EFED8";
    padding: 23px 0 0 0;

    .hospital-title {
      font-family: Figtree;
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: center;
      color: #1a1a1a;
      margin: 0;
    }

    @media (max-width: 600px) {
      padding: 12px;
      .hospital-title {
        font-size: 18px;
      }
    }
  `;

  return (
    <>
      <SectionWrapper background={kenkoDeepTeal}>
        {matchesXS ? <HeroSectionMobile /> : <HeroSection />}
      </SectionWrapper>

      {showDownloadAppModal ? <DownloadAppModal /> : null}
      <HospitalWrapper>
        <HospitalSection />
      </HospitalWrapper>

      <SectionWrapper background="#EFEDD8">
        <BenefitSection />
      </SectionWrapper>

      {newFlow ? (
        <NewPlansSection plans={plans} homePage={true} />
      ) : (
        <PlansSection plans={plans} />
      )}

      {!isMobileApp ? (
        <>
          {/* <SectionWrapper background={kenkoPureWhite}>
            <KenkoSteps />
          </SectionWrapper> */}

          {/* <YouTubeSection /> */}

          <SectionWrapper background={kenkoPureWhite}>
            {/* @ts-ignore */}
            <ParallaxWrapper />
          </SectionWrapper>

          <SectionWrapper background={kenkoPureWhite}>
            <KenkoVerse />
          </SectionWrapper>

          <SectionWrapper background="#F6F6F6">
            <TrustedBySection />
          </SectionWrapper>

          {/* <SectionWrapper background={kenkoPureWhite}>
            <PressSection />
          </SectionWrapper> */}

          <div id="testimonial">
            <TestimonialSection />
          </div>

          <SectionWrapper background={kenkoPureWhite}>
            {matchesXS ? <BlogSectionMobile /> : <BlogSection />}
          </SectionWrapper>

          <SectionWrapper background={lightBg}>
            <FAQSection referral={false} />
          </SectionWrapper>

          <SectionWrapper background={kenkoPureWhite}>
            <Article />
          </SectionWrapper>

          <SectionWrapper background={mossGreen50}>
            <ContactSection />
          </SectionWrapper>
        </>
      ) : null}
    </>
  );
}

export default HomePage;
