import styled from "@emotion/styled";

export const KenkoVerseTitle = styled.p`
  color: #243242;
  text-align: center;
  font-family: Figtree;
  font-size: 42px;
  font-weight: 600;
  line-height: 54px; /* 128.571% */

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const KenkoVerseSection = styled.div`
  padding: 0 6rem 64px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  /* grid-template-columns: repeat(autofill); */
  grid-template-rows: 1fr;
  align-items: center;
  gap: 75px;
  margin-top: 8rem;

  @media (max-width: 600px) {
    margin-top: 1rem;
  }

  .kenko-video {
    position: relative;
  }

  .yt-button {
    cursor: pointer;
    outline: none;
    background: transparent;
    border: none;
    position: absolute;
    bottom: 2.65rem;
    right: 1.05rem;
  }

  @media (max-width: 600px) {
    /* display: flex; */
    overflow-x: auto;
    gap: 32px;
    padding: 1rem 32px;

    /* .yt-frame {
      width: 300px;
      height: 270px;
    }

    .yt-button {
      bottom: 1.9rem;
      right: 0.7rem;
    }
    .yt-button-image {
      height: 45px;
      width: 45px;
    } */
  }

  ::-webkit-scrollbar {
    width: 0;
  }
`;
