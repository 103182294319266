import React, { useState, useCallback } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import Link from "next/link";
import { Grid } from "@mui/material";
import { imageParser } from "../../shared/utils/image.utils";
import Button from "../../shared/components/Button/Button";
import { homePageContent } from "../../content/articles/homePageContent";
import { pageContent299 } from "../../content/articles/299PageContent";
import { pageContent599 } from "../../content/articles/599PageContent";
import { pageContent999 } from "../../content/articles/999PageContent";
import { pageContentDiabetes } from "../../content/articles/DiabetesPageContent";
import { pageContentSeniorCitizen } from "../../content/articles/SeniorCitizenPlanContent";
import { setPlan } from "../../slices/auth-slice";
import CloseIcon from "@mui/icons-material/Close";
import { useClickOutside } from "../../hooks";
import { ArticleWrapper } from "./ArticleWrapper";
import GA from "../../shared/utils/ga.utils";
import Moengage from "../../shared/utils/moengage.utils";

function Article() {
  const router = useRouter();
  const [readMore, setReadMore] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [articleSection, setArticleSection] = useState("");

  const filterRef = useClickOutside(() => setReadMore(false));

  const paths = router.asPath.split("/");
  const page = paths[paths.length - 1];
  const dispatch = useDispatch<any>();

  const getArticleContent = useCallback(() => {
    switch (page) {
      case "":
        return homePageContent;
      case "299-Mini-Plan":
        return pageContent299;
      case "599-Individual-Plan":
        return pageContent599;
      case "999-Family-Plan":
        return pageContent999;
      case "Diabetes-Plan":
        return pageContentDiabetes;
      case "Senior-Citizen-Plan":
        return pageContentSeniorCitizen;
      default:
        return {
          TITLE: "",
          IMAGE: "",
          IMAGE_ALT: "",
          HERO_CONTENT: [],
          CONTENT: [],
          ENDING_CONTENT: [],
          CTA_BUTTON: "",
          ROUTE: "",
          SUB_TITLE: "",
          CARD_IMAGE: "",
          MOBILE_IMAGE: "",
          BLUR_IMAGE: "",
        };
    }
  }, [page]);

  const handleReadMore = () => {
    setReadMore(true);
    GA.event({
      action: "read_more",
      params: {
        event_category: "Button Click",
        event_label: "Read More",
      },
    });
    Moengage.pushEvent("web_article_readmore_clicked");
  };

  return getArticleContent()?.TITLE ? (
    <ArticleWrapper>
      {getArticleContent() ? (
        <Grid justifyContent="center" container className="article-section">
          <h2 className="article-Mainheading">{getArticleContent()?.TITLE}</h2>
          <Grid container justifyContent="left" className="article-container">
            {getArticleContent()?.CARD_IMAGE && (
              <Grid
                className="img-container-cls"
                sx={{ width: "100%" }}
                item
                md={getArticleContent().CARD_IMAGE ? 3 : 0}
              >
                <img
                  src={imageParser(getArticleContent().CARD_IMAGE)}
                  className="img-cls"
                  alt={getArticleContent().IMAGE_ALT}
                />
                <img
                  src={imageParser(getArticleContent().MOBILE_IMAGE)}
                  className="img-mobile-cls"
                  alt={getArticleContent().IMAGE_ALT}
                />
              </Grid>
            )}
            <Grid
              className="article-content-container"
              item
              alignItems="center"
              md={getArticleContent().CARD_IMAGE ? 9 : 12}
            >
              <span className="article-subHeading">
                {getArticleContent()?.SUB_TITLE}
              </span>
              <div className="article-content">
                {getArticleContent().HERO_CONTENT.map((content) => (
                  <div key={content.ID}>{content.CONTENT}</div>
                ))}
                {/* <span>
                  {getArticleContent().CONTENT.map((content) => {
                    return (
                      <>
                        <h2
                          id={`${content?.SUBHEADING}`}
                          className="article-modal-subheading"
                          key={"article" + content.ID}
                        >
                          {content.SUBHEADING}
                        </h2>
                        <div key={content.ID}>{content.CONTENT} </div>
                      </>
                    );
                  })}
                </span> */}
                <div style={{ marginTop: "32px" }}>
                  {getArticleContent().ENDING_CONTENT.map((content, idx) => (
                    <div
                      key={content.ID}
                      style={{ marginTop: idx == 1 ? "44px" : "16px" }}
                    >
                      {content.CONTENT}
                    </div>
                  ))}
                </div>
              </div>
              <Button
                label="Read more"
                className="read-more-btn"
                variant="contained"
                onClick={handleReadMore}
              />
              <span className="read-more-btn-mobile" onClick={handleReadMore}>
                Read more
              </span>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {readMore ? (
        <Grid container className="article-modal">
          <Grid
            justifyContent="center"
            ref={filterRef}
            item
            className="article-modal-section"
          >
            <Grid
              justifyContent="center"
              className="article-modal-title-container"
              item
              xs={12}
            >
              <span className="article-modal-title">
                {getArticleContent()?.TITLE}
              </span>{" "}
              <CloseIcon
                className="article-modal-close-btn"
                onClick={() => setReadMore(false)}
              />
            </Grid>
            <Grid item className="article-modal-content-container" xs={12}>
              <Grid
                justifyContent="center"
                className="article-modal-image-container"
                xs={12}
                item
              >
                {getArticleContent()?.IMAGE && (
                  <img
                    src={
                      loaded
                        ? imageParser(getArticleContent().IMAGE)
                        : imageParser(getArticleContent().BLUR_IMAGE)
                    }
                    alt={getArticleContent().IMAGE_ALT}
                    className="img-modal-cls"
                    onLoad={() => {
                      setLoaded(true);
                    }}
                  />
                )}
              </Grid>
              <Grid item className="article-chips-container">
                {getArticleContent().CONTENT.map((content) => {
                  if (content?.SUBHEADING) {
                    return (
                      <div
                        onClick={(e) => {
                          setArticleSection(content?.SUBHEADING);
                          e.preventDefault();
                          window.location.replace(
                            `${router.asPath}#${content?.SUBHEADING}`
                          );
                        }}
                        key={content?.ID}
                        className={`article-chip ${
                          content?.SUBHEADING === articleSection
                            ? "article-selected-chip "
                            : null
                        }`}
                      >
                        {content?.SUBHEADING}
                      </div>
                    );
                  }
                })}
              </Grid>
              <span className="article-modal-content">
                <h2>{getArticleContent()?.SUB_TITLE}</h2>
              </span>
              <span className="article-modal-content">
                {getArticleContent().HERO_CONTENT.map((content) => (
                  <div key={content.ID}>{content.CONTENT}</div>
                ))}
              </span>
              <span className="article-modal-content">
                {getArticleContent().CONTENT.map((content) => {
                  console.log(content?.SUBHEADING, "content?.SUBHEADING");
                  return (
                    <>
                      <h2
                        id={`${content?.SUBHEADING}`}
                        className="article-modal-subheading"
                        key={content.ID}
                      >
                        {content.SUBHEADING}
                      </h2>
                      <div key={content.ID}>{content.CONTENT} </div>
                    </>
                  );
                })}
              </span>
              <span className="article-modal-content">
                {getArticleContent().ENDING_CONTENT.map((content) => (
                  <div key={content.ID}>{content.CONTENT}</div>
                ))}
              </span>
              <Link href={getArticleContent().ROUTE} passHref>
                <Button
                  label={getArticleContent().CTA_BUTTON}
                  className="cta-btn"
                  variant="contained"
                  onClick={() => {
                    if (page) {
                      dispatch(setPlan(page));
                    }
                  }}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </ArticleWrapper>
  ) : null;
}

export default Article;
