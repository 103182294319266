export const pageContent999 = {
  IMAGE: "articles/999-Family-Plan-article.svg",
  CARD_IMAGE: "articles/999-Family-Plan.svg",
  MOBILE_IMAGE: "articles/999-Family-Plan-mobile.svg",
  BLUR_IMAGE: "articles/999-Family-Plan-article-blur.png",
  IMAGE_ALT: "Explore Kenko Family Health plan to meet your needs",
  TITLE: "Expensive Family Healthcare Plans Got You Confused?",
  SUB_TITLE: "We Have The Solution!",

  HERO_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          It's a decision that can take weeks and even months to make, and it
          still may not be the right decision made. It's up to you to ensure
          that you have an effective family healthcare plan for your family, and
          usually, healthcare plan providers do not make it easy.
        </p>
      ),
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          They can make the process extremely confusing by offering a range of
          confusing family health plans that never clearly state the benefits of
          obtaining a health plan for the family. Family floater plans most
          often do not give a complete summary of the benefits that family
          medical plans can offer.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          This leads to dissatisfaction with the acquiring of{" "}
          <a
            href="https://kenkohealth.in/plans"
            target="_blank"
            rel="noreferrer"
          >
            health plan
          </a>{" "}
          for most families. A medical plan for a family that does not give
          full-health benefits can make you worrisome in the future when you get
          any medical emergency issues.
        </p>
      ),
    },
    {
      ID: 4,
      CONTENT: (
        <p>
          At Kenko, we have designed our group health plans to make this journey
          easier and more flexible for you. Kenko's 999 Family Plan has been
          designed to provide you and your loved ones. Our group health plan
          provides comprehensive protection while clamping down on the
          unnecessary red tape and bureaucracy that a group healthcare policy
          usually involves.
        </p>
      ),
    },
  ],

  CONTENT: [
    {
      ID: 1,
      SUBHEADING:
        "Wary Of Rainy Days? Get Family Healthcare Plan Subscriptions That Look After Your Entire Family Health",
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          Group health plans are essential, but can be an expensive affair. In
          fact, getting independent plans for all members of the family could
          cost you and your family lakhs of rupees every year. Family Healthcare
          Plans protect you and your loved ones while ensuring that the costs
          entailing these plans don't burn a hole in your pocket.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          At Kenko, we have studied some of the best advanced family medical
          plans for families in India today and created a product that offers
          all that traditional{" "}
          <a href="https://kenkohealth.in/" target="_blank" rel="noreferrer">
            healthcare
          </a>{" "}
          providers do, and more. With family medical plans, our group health
          plans focus and ensure not just on protecting your finances during
          stressful days of hospitalisation, but also on giving you savings all
          the year around. That's a win for your loved ones and you, 365 days of
          the year of family healthcare plan.
        </p>
      ),
    },
    {
      ID: 4,
      SUBHEADING:
        "The Kenko 999 Family Plan – Comprehensive Benefits Applied, Unmatched Savings",
    },
    {
      ID: 5,
      CONTENT: (
        <p>
          Kenko's 999 Family Plan has been designed to ensure that you benefit
          from the offerings of its family health care plan every single day. It
          extends beyond the traditional group health plan or 'parents medical
          health benefits ' everyone imagines and gives you consistent savings
          throughout the year. We have achieved this feat by focusing on
          lifestyle diseases, addressing medical inflation, and keeping in mind
          the small day-to-day medical requirements of your loved ones that pile
          up in cost across the year. Kenko’s 999 Family Health Plan remains an
          unchallenged group medicare or the health plan for family.
        </p>
      ),
    },
    {
      ID: 6,
      SUBHEADING: "Helping You Beat Lifestyle Diseases",
    },
    {
      ID: 7,
      CONTENT: (
        <p>
          Lifestyle diseases have grown rampantly over the past decade, and
          today this is reflected in the younger demographics too. Indians under
          45 years of age are struggling with diabetes, high-cholesterol levels,
          obesity, and odd aches and pains. This change can be attributed to a
          heavier workload and a more sedentary lifestyle.
        </p>
      ),
    },
    {
      ID: 8,
      CONTENT: (
        <p>
          Regular health check-ups can help address these lifestyle diseases and
          catch them early. However, the cost of consulting a doctor may hold
          you back from attending such check-ups, opting to book an appointment
          instead, only when you fall ill. Kenko’s family floater health plans
          can make your family visits to the doctor’s easier and more frequent
          through attractive offers.
        </p>
      ),
    },
    {
      ID: 9,
      CONTENT: (
        <p>
          At Kenko, we encourage you to clock in for those doctors’ visits by
          offering 50% off through the 999 Family Plan. Your group health plan
          may not provide you with similar concessions, and we make sure that
          the cost of a visit doesn't stop you from detecting a lifestyle
          disease early. Thus, this family floater plan is mostly intended to
          help in curing your lifestyle diseases earlier with early detection.
        </p>
      ),
    },
    {
      ID: 10,
      SUBHEADING: "Kenko Family Healthcare Plan Looks After Your Entire Family",
    },
    {
      ID: 11,
      CONTENT: (
        <p>
          The family floater health plan offered by Kenko provides benefits for
          your entire family, helping you avoid the chaos of maintaining and
          paying for multiple family medical plans or healthcare plans. Due to
          factors such as age and gender, every family member can be vulnerable
          to completely different health complications. We help address all your
          medical needs in Family Healthcare Plans. Also, we at Kenko with the
          family health care plan offer{" "}
          <a
            href="https://kenkohealth.in/plan/599-Individual-Plan"
            target="_blank"
            rel="noreferrer"
          >
            50% discounts on all medicines ordered online
          </a>
          , ranging from daily vitamins and supplements to life-saving diabetes
          and heart medication.
        </p>
      ),
    },
    {
      ID: 12,
      CONTENT: (
        <p>
          Therefore, through this family protection plan, no matter who it may
          be in your family, that requires attention at the moment, Kenko’s
          family health care plan has you and your loved ones provided for in
          this group medicare.
        </p>
      ),
    },
    {
      ID: 13,
      CONTENT: (
        <p>
          Our 999 Family Healthcare Plan, as a group medical plan, also offers
          three additional days for hospital benefits to address the entire
          family's needs. All of this is offered at a highly reasonable cost.
          This family floater plan just keeps a watch on healthcare for family.
          Believe us, your family will be thanking you soon for the benefits and
          the savings obtained from this group medicare.
        </p>
      ),
    },
    {
      ID: 14,
      SUBHEADING: "Addresses Inadequate Healthcare",
    },
    {
      ID: 15,
      CONTENT: (
        <p>
          At the moment, you may already have a group healthcare plan for your
          family or a health plan for family. Alternatively, your employer may
          be offering you some sort of medical benefits or family protection
          plan. However, these plans often have limitations, and therefore, they
          fail to account for the daily expenses of looking after your loved
          ones. At Kenko, we see to the completeness of a family health plan, we
          can add up plans to your existing plan or can suggest a complete plan
          after doing the complete survey about the plans you have.
        </p>
      ),
    },
    {
      ID: 16,
      CONTENT: (
        <p>
          Kenko adds to the security offered by these family health plans by
          focusing on everyday savings and giving you access to doctors,
          medicine delivery, lab tests and diagnostic tests at great discounts.
          Kenko boosts the protection offered by your current family health
          plans and keeps you away from exorbitant top-up family health plans or
          gaps in family healthcare. These family health plans add up to the
          benefits and thus can make you tension free when health issues crop up
          in an emergency.
        </p>
      ),
    },
    {
      ID: 17,
      SUBHEADING: "Reasons To Opt For The Kenko 999 Family Plan",
    },
    {
      ID: 18,
      CONTENT: (
        <p>
          Kenko 999 Family Plan offers some of the most unique solutions that
          benefit you and your loved ones while reducing the dependency of
          millions on archaic family healthcare plans. The family medical plans
          act as full family protection plans from the point of view of the
          hospitalisation. The family floater health plan gives benefits on
          doctors’ consultation, laboratory tests, diagnostic tests, and
          delivery of medicines at discounted rates, on buying from the online
          Kenko app.
        </p>
      ),
    },
    {
      ID: 19,
      CONTENT: (
        <p>
          Kenko 999 Family Plan takes complete healthcare for family of parents
          and their loved ones. Thus, it becomes a group medical plan when it
          comes to many members in a single family. This medical plan for family
          helps the family be assured of any unnecessary stress. Also, it always
          becomes a family protection plan as it protects the family from all
          types of health issues. This family floater health plan gives you and
          your family a cashless hospital treatment as well as pre- and
          post-hospitalisation benefits.
        </p>
      ),
    },
  ],

  ENDING_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          However, one aspect of the health plan for family remains the same:
          the earlier you get yourself a family health plan, the better it is
          for your family. So, take a few moments to get your Kenko Score and
          give your loved ones the protection of a medical plan for family with
          the Kenko 999 Family Plan today.
        </p>
      ),
    },
  ],

  CTA_BUTTON: "Get Your Subscription Now",
  ROUTE: "/kenko-score",
};
