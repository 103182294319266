export const pageContentSeniorCitizen = {
  IMAGE: "articles/Senior-Citizen-Plan-article.svg",
  CARD_IMAGE: "articles/Senior-Citizen-Plan.svg",
  MOBILE_IMAGE: "articles/Senior-Citizen-Plan-mobile.svg",
  BLUR_IMAGE: "articles/Senior-Citizen-Plan-article-blur.png",
  IMAGE_ALT:
    "Get the Best Care for Your Elders with Kenko's Senior Citizen Plan",
  TITLE: "Struggling To Find The Right Health Plan For Senior Citizens?",
  SUB_TITLE: " Kenko’s Got Your Back",

  HERO_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          It's not an urban myth; good health plans exist for senior citizens
          that give you discounts on doctor consults, lab tests, and medicines.
        </p>
      ),
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          Healthcare services in India are getting increasingly expensive each
          year, and an adequate healthcare plan is pivotal for you and your
          entire family. This can be especially crucial when it comes to the
          elderly members of your family, as their rising health costs coincide
          with more expensive healthcare plans.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          At an elderly age, there is an increase in the number of ailments they
          are vulnerable to. They can easily become frequent fliers at
          hospitals. Therefore, getting a good medical plan for senior citizens
          in your family is an essential responsibility you need to shoulder.
        </p>
      ),
    },
    {
      ID: 4,
      CONTENT: (
        <p>
          Even if your parents or grandparents have a group healthcare plan, it
          may not be enough. It makes complete sense to supplement even the best
          healthcare plans for senior citizens with additional protection. You
          could do so with Kenko’s Senior Citizen Plan.
        </p>
      ),
    },
    {
      ID: 5,
      CONTENT: (
        <p>
          Finding the right healthcare plans for senior citizens includes
          ensuring comprehensive benefits against various age-related ailments.
          Having Kenko Senior Citizen Plan in place will provide access to
          numerous benefits, such as getting benefits in the costs of in-patient
          hospitalisation, savings on doctor consultations, and subsidised
          medical care.
        </p>
      ),
    },
    {
      ID: 6,
      CONTENT: (
        <p>
          Let’s first take a moment to understand what regular healthcare plans
          for senior citizens entail.
        </p>
      ),
    },
  ],

  CONTENT: [
    { ID: 0, SUBHEADING: "Senior Citizen Plan" },
    {
      ID: 1,
      CONTENT: (
        <p>
          As the name suggests, medical healthcare plans for senior citizens
          look after people usually older than 60 years of age. Like other
          healthcare products, this health plan for senior citizens is a simple
          contract between the end user, the senior citizens, and the healthcare
          provider.
        </p>
      ),
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          However, the subscription costs are often higher in this case, which
          healthcare providers justify under the label of additional medical
          expenses required by senior citizens. This exploitative practice may
          often mean that adequate medical plans for senior citizens alone can
          cost tens of thousands of rupees yearly.
        </p>
      ),
    },
    {
      ID: 3,
      CONTENT: (
        <p>
          So, a good medical plan for senior citizens is necessary to save them
          from such expensive treatment and medicines.
        </p>
      ),
    },
    {
      ID: 4,
      CONTENT: (
        <p>
          These healthcare plans for senior citizens usually include a range of
          expenses, such as hospitalisation, doctor visits, pre and
          post-hospitalisation, etc.
        </p>
      ),
    },
    {
      ID: 5,
      CONTENT: (
        <p>
          Every healthcare plan is different and must therefore be examined
          before you opt for one. This confusing system can often be immensely
          stressful, which is why Kenko has launched one single health plan for
          senior citizens to protect your parents and elderly loved ones.
        </p>
      ),
    },
    {
      ID: 6,
      SUBHEADING:
        "The Kenko Senior Citizen Plan — An Additional Layer Of Protection",
    },
    {
      ID: 7,
      CONTENT: (
        <p>
          The Kenko Senior Citizen Plan was created after we examined the vast
          array of healthcare plans for senior citizens and families. We
          realised that very few of them offered day-to-day benefits. For
          example, there is no plan that offers senior citizens discounts on
          medicines.
        </p>
      ),
    },
    {
      ID: 8,
      CONTENT: (
        <p>
          Our research indicated that there was a spike in the support needed
          for older residents in India post-pandemic. Kenko’s Health Plan for
          senior citizens has, therefore, been designed keeping in mind their
          requirements.
        </p>
      ),
    },
    {
      ID: 9,
      CONTENT: (
        <p>
          Senior citizens are highly vulnerable to illnesses and may frequently
          need medical attention, thus making senior citizens a discount for
          medicine expenses. Looking at the demand for free medical check-ups
          for senior citizens, we offer savings on a wide range of necessary
          services, along with benefits for hospital costs.
        </p>
      ),
    },
    {
      ID: 10,
      CONTENT: (
        <p>
          Furthermore, as a new-age healthtech company, Kenko breaks away from
          all the unnecessary restrictions and paperwork of traditional
          healthcare providers. We focus more on consumer convenience, giving
          you access to all your plan details and offerings in a few taps.
        </p>
      ),
    },
    {
      ID: 11,
      CONTENT: (
        <p>
          Plus, unlike traditional companies, we don’t believe in confusing
          customers with heavy jargon. With Kenko, you will get direct, clear
          information about your plans so that the health plan for senior
          citizens is well understood by them too.
        </p>
      ),
    },
    {
      ID: 12,
      CONTENT: (
        <p>
          We sincerely hope our medical plan for senior citizens helps them
          secure their health while not having to worry about their finances.
          So, forgo those messy and complicated medical plans for senior
          citizens and have a look at the offerings Kenko has put together for
          you.
        </p>
      ),
    },
    {
      ID: 13,
      SUBHEADING: "Kenko’s Game-changing Offerings For The Elders In Your Life",
    },
    {
      ID: 14,
      CONTENT: (
        <p>
          Here’s our opportunity to walk the talk. The following are the
          benefits that Kenko’s Senior Citizen Plan entails:
        </p>
      ),
    },
    {
      ID: 15,
      CONTENT: <h3>1. Comprehensive Hospital Benefits</h3>,
    },
    {
      ID: 16,
      CONTENT: (
        <p>
          Hospitalisation can be immensely draining on one’s body as well as
          their savings. Unfortunately, as our loved ones get older, they face
          the vulnerability of health, and there is a higher probability of
          hospital visits.
        </p>
      ),
    },
    {
      ID: 17,
      CONTENT: (
        <p>
          Recovery is important, but the bills keep piling up with each passing
          day. This is especially true in India, as evidenced by India’s medical
          inflation standing at 14%, where additional measures and tests alone
          can cost lakhs of rupees.
        </p>
      ),
    },
    {
      ID: 18,
      CONTENT: (
        <p>
          Such stays can be immensely stressful and give the entire family
          enough to worry about. This is why the biggest priority for Kenko is
          focusing on hospital benefits so that a medical emergency doesn’t
          evolve into a financial one.
        </p>
      ),
    },
    {
      ID: 19,
      CONTENT: (
        <p>
          As part of the Senior Citizen Plan, Kenko offers an incredible ten
          days of hospital benefits. These ensure that we focus on your finances
          while you focus on the recovery of your loved ones. Kenko’s
          comprehensive benefits engulf an extensive range of illnesses.
        </p>
      ),
    },
    {
      ID: 20,
      CONTENT: (
        <p>
          That’s complete peace of mind for you when it comes to medical
          emergencies for your elders, at just Rs. 1499/- per month. In this
          way, a complete health plan for senior citizens can tackle their
          health issues and reduce their hospitalisation expenses.
        </p>
      ),
    },
    {
      ID: 21,
      CONTENT: <h3>2. Save On Doctor’s Appointments</h3>,
    },
    {
      ID: 22,
      CONTENT: (
        <p>
          Senior citizens often require regular doctor visits to deal with the
          ailments that accompany old age. This can include a range of issues,
          from infections to physiological problems.
        </p>
      ),
    },
    {
      ID: 23,
      CONTENT: (
        <p>
          Specialists are expensive, but regular care, which is needed to ensure
          that these issues do not worsen at a rapid pace, is equally
          hole-burning in your pockets. Regular healthcare is particularly
          important for severe illnesses such as Alzheimer’s and Osteoporosis.
        </p>
      ),
    },
    {
      ID: 24,
      CONTENT: (
        <p>
          A simple free medical check-up for senior citizens can prevent the
          severity of these diseases. The medical plan for senior citizens also
          needs to look after regular doctor visits so that their health remains
          mostly free from ailments.
        </p>
      ),
    },
    {
      ID: 25,
      CONTENT: (
        <p>
          The expenses associated with these medical visits can keep rising,
          which is why Kenko offers you 20% off on doctor appointments. These
          savings help you reduce medical costs upfront while immensely
          impacting your expenses in the long run.
        </p>
      ),
    },
    {
      ID: 26,
      CONTENT: (
        <p>
          The medical plan for senior citizens takes care of all the doctor
          visits and treatment expenses. What’s more? Kenko has tied up with a
          vast network of specialists and practitioners in the country, so your
          loved ones can access the best care India offers. All without burning
          a hole in your pocket!
        </p>
      ),
    },
    {
      ID: 27,
      CONTENT: <h3>3. Medications Made Affordable</h3>,
    },
    {
      ID: 28,
      CONTENT: (
        <p>
          Ageing gradually increases our dependency on medication. This can
          range from daily supplements and vitamins to essential medical aid
          needed to fight chronic illnesses. The bills for these medicines can
          stack up, and regular pharmacy visits can also be a pain of their own
          kind.
        </p>
      ),
    },
    {
      ID: 29,
      CONTENT: (
        <p>
          If you don’t want the medications of the elderly to be a financial
          burden, then you may want to invest in a plan that gives free medical
          check-ups for senior citizens and senior citizen medicine discounts.
        </p>
      ),
    },
    {
      ID: 30,
      CONTENT: (
        <p>
          Kenko’s Senior Citizen Plan addresses this problem by offering your
          parents 20% off on all their medical needs. There are very few
          pharmacies that offer senior citizen discounts on over-the-counter
          medicines. However, Kenko Senior Citizen Plan ensures they get
          discounts on all medicines and much more.
        </p>
      ),
    },
    {
      ID: 31,
      CONTENT: (
        <p>
          This ensures that such expenses never get out of hand for senior
          citizens. Plus, Kenko has partnered with Tata 1mg to eliminate the
          need to visit pharmacies frequently. Tata 1mg, India’s most trusted
          online pharmacy, delivers your medicines directly to your home without
          extra delivery costs. Convenience meets savings when it comes to the
          medication you need, only with Kenko.
        </p>
      ),
    },
    {
      ID: 32,
      CONTENT: <h3>4. Discounts On Lab Tests</h3>,
    },
    {
      ID: 33,
      CONTENT: (
        <p>
          As with every other medical expense, the frequency of laboratory tests
          also increases once the elderly reach a particular age. Advanced tests
          today can cost thousands of rupees. Additionally, doctors tend to
          prescribe a number of them together for preventive care or diagnosis.
        </p>
      ),
    },
    {
      ID: 34,
      CONTENT: (
        <p>
          Comprehensive medical care is only attainable when medical
          professionals have a complete idea of what their patients are going
          through. You might wonder, can senior citizen discounts on medicines
          be extended to medical tests too?
        </p>
      ),
    },
    {
      ID: 35,
      CONTENT: (
        <p>
          The answer is yes! Kenko tackles this expense by offering you 20% off
          on every lab test for senior citizens. This significantly reduces the
          cost of a diagnosis and gives your loved ones a complete assessment of
          their body while helping you save money.{" "}
        </p>
      ),
    },
    {
      ID: 36,
      CONTENT: (
        <p>
          Additionally, Kenko has associations with leading laboratories in
          India, ensuring you get reliable and trustworthy results.
        </p>
      ),
    },
    {
      ID: 37,
      CONTENT: <h3>5. Affordable Healthcare Plan</h3>,
    },
    {
      ID: 38,
      CONTENT: (
        <p>
          Senior citizens often have to shell out huge amounts of money to get a
          good healthcare plan. The cost of avoiding high medical bills can be
          extremely high for the elders in your life. Additionally, most
          healthcare providers often take undue advantage of their age and
          physical condition, jacking up the prices of their healthcare.
        </p>
      ),
    },
    {
      ID: 39,
      CONTENT: (
        <p>
          Kenko makes sure that the health plans for senior citizens are
          affordable for everyone. Priced at just Rs.1499/- a month, our Senior
          Citizen Plan protects your loved ones without exploiting them for
          their age. Plus, the upfront savings it offers on doctor visits,
          medicines, and{" "}
          <a
            href="https://kenkohealth.in/plan/599-Individual-Plan"
            target="_blank"
            rel="noreferrer"
          >
            lab tests
          </a>{" "}
          make taking care of their health far more affordable.
        </p>
      ),
    },
  ],

  ENDING_CONTENT: [
    {
      ID: 1,
      CONTENT: (
        <p>
          Senior citizens have long had to deal with expensive healthcare plans
          and inadequate benefits with these plans. The rising costs of
          healthcare mean that they spend the final few decades of their lives
          in a constant state of anxiety and financially burdened. Kenko Senior
          Citizen Plan addresses such issues head-on, giving your loved ones the
          protection they deserve at an affordable price.
        </p>
      ),
    },
    {
      ID: 2,
      CONTENT: (
        <p>
          Make them a part of the Kenko family today and give them the peace of
          mind and medical care they deserve. Remember, good health is
          priceless, but your healthcare plan can always be a little more
          affordable.
        </p>
      ),
    },
  ],

  CTA_BUTTON: "Get Your Subscription Now",
  ROUTE: "/kenko-score",
};
