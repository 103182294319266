import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Typography from "../Typography/Typography";
import Paper from "../Paper/Paper";
import { imageParser } from "../../utils/image.utils";

const PlanCardWrapper = styled.div`
  display: flex;
  justify-content: center;

  .plan-card {
    border-radius: 20px;
    position: relative;
    color: var(--kenkoPureWhite);
    box-shadow: 0px 18px 38px rgba(123, 123, 123, 0.14);
  }
  .plan-card .header {
    display: flex;
    border-radius: 12px 12px 0 0;
    padding: 35px 16px 12px 16px;

    @media (max-width: 600px) {
      padding: 30px 16px 12px 16px;
    }
  }

  .plan-card .header .plan-name {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 2px;
    text-align: left;

    @media (max-width: 600px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0px;
    }
  }

  .plan-card .header .plan-subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #9fa5ac;

    @media (max-width: 600px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .plan-card .bestseller {
    position: absolute;
    top: 15px;
    left: 0px;
  }
  .plan-card .price-details {
    font-size: 28px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 16px;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  .plan-card .avatar {
    height: 55px;
    position: absolute;
    width: 72px;
    top: 15px;
    right: 15px;

    @media (max-width: 600px) {
      height: 32px;
      width: 44px;
      top: 10px;
      right: 10px;
    }
  }

  .plan-card .avatar-lg {
    position: absolute;
    top: -38px;
    height: 71px;
    width: 71px;
    @media (max-width: 600px) {
      height: 50px;
      width: 50px;
    }
  }
  .plan-card .duration {
    font-weight: 500;
    font-size: 16px;
  }
  .plan-card .body {
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .plan-card .benefit-container {
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 42px;
  }

  .plan-card .benefit-container .benefit-title {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .plan-card .benefit-container .benefit-description {
    color: #737f8c;
    font-size: 14px;
    font-weight: 400;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  .plan-card .benefit-container .benefit-icon {
    min-width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .plan-card .divider {
    height: 1px;
    width: 100%;
  }

  .plan-card .benefit-container .discount-chip {
    background-color: #d1ddb7;
    color: #003032;
    font-weight: 700;
    border-radius: 0 15px 15px 0;
    padding: 6px 12px;
    margin-right: 4px;
    font-size: 12px;
    width: fit-content;
    @media (max-width: 600px) {
      padding: 4px 12px;
    }
  }
  .plan-card .tick-icon {
    width: 20px;
    height: 27px;
    @media (max-width: 600px) {
      width: 22px;
      height: 27px;
    }
  }
  .plan-card .tick-icon2 {
    height: 21px;
    @media (max-width: 600px) {
      height: 16px;
    }
  }
  .plan-card .action {
    padding: 8px 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    bottom: 40px;
    position: absolute;

    width: 100%;

    @media (max-width: 600px) {
      padding: 8px 16px 65px;
      bottom: -15px;
    }
  }

  .plan-card .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--steel-steel-800, #243242);
    border-radius: 0 0 20px 20px;
    font-weight: 500;
    @media (max-width: 600px) {
      padding: 10px;
      font-size: 11px;
    }
  }

  .plan-card .benefit-container-title {
    color: #fafafa;
    font-size: 16px;
    padding: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .link {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.322516px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #003032;
    opacity: 0.6;
  }
  .slide {
    transform: scale(0.7);
    transition: transform 500ms ease-in-out;
  }
  .slick-slide {
    text-align: center;
    transition: transform 500ms ease-in-out;
    margin: 10px;
  }
  .slide-active {
    transform: scale(1);
  }
`;

export default function NewPlanCard({
  name,
  subTitle,
  image,
  price,
  duration,
  benefits,
  primaryAction,
  secondaryAction,
  width,
  height,
  style,
  transformStyle,
  colorScheme,
  bestSeller,
}) {
  return (
    <PlanCardWrapper>
      <Paper
        elevation={0}
        className={`plan-card  ${transformStyle}`}
        style={{
          width,
          height,
          ...style,
          backgroundColor: colorScheme?.primaryColor,
        }}
      >
        <div className="header">
          {bestSeller && (
            <div className="bestseller">
              <img
                src={imageParser("plans_section/best_seller.svg")}
                alt="Bestseller"
              />
            </div>
          )}
          <div>
            <div
              className="plan-name"
              style={{
                marginTop: "1rem",
                color: colorScheme?.planTitleColor,
                textTransform: "uppercase",
              }}
            >
              {name}
            </div>
            <div
              className="plan-subtitle"
              style={{ color: colorScheme?.planSubtitleColor }}
            >
              {subTitle ?? ""}
            </div>

            <div
              className="price-details"
              style={{ color: colorScheme?.planPriceColor }}
            >
              <Typography>
                ₹{price || "NA"}
                <span className="duration">/{duration}</span>
              </Typography>
            </div>
          </div>
        </div>

        {image && (
          <div className="avatar">
            <img src={image} alt="" height={"100%"} width={"100%"} />
          </div>
        )}

        <div
          className="divider"
          style={{ backgroundColor: colorScheme?.secondaryColor }}
        ></div>

        <div
          className="benefit-container-title"
          style={{ color: colorScheme?.benefitTenureColor }}
        >
          Annual benefits
        </div>
        <div className="body">
          {benefits?.map((benefit) => (
            <div key={benefit.index} className="benefit-container">
              <div
                className="benefit-icon"
                style={{ backgroundColor: colorScheme?.secondaryColor }}
              >
                <img src={benefit?.icon} alt="" height={24} width={24} />
              </div>

              <div className="benefit-text-container">
                <div
                  className="benefit-title"
                  style={{ color: colorScheme?.benefitTitleColor }}
                >
                  {benefit?.title}
                </div>
                <div
                  className="benefit-description"
                  style={{ color: colorScheme?.benefitDescriptionColor }}
                >
                  {benefit?.subTitle}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="action">
          {secondaryAction && secondaryAction}
          {primaryAction && primaryAction}
        </div>
        <div
          className="footer"
          style={{
            color: colorScheme?.emiBarTextColor,
            backgroundColor: colorScheme?.secondaryColor,
          }}
        >
          No cost EMI available on 15+ banks
        </div>
      </Paper>
    </PlanCardWrapper>
  );
}

NewPlanCard.propTypes = {
  /**
   * The name of the plan
   */
  name: PropTypes.string,
  /**
   * The price of the plan
   */
  price: PropTypes.number,
  /**
   * The discount of the plan
   */
  discount: PropTypes.any,
  /**
   * The duration of the plan
   */
  duration: PropTypes.string,
  /**
   * The image of the plan
   */
  image: PropTypes.object,
  /**
   * The benefits of the plan
   */
  benefits: PropTypes.array.isRequired,
  /**
   * The primary action of the plan card
   */
  primaryAction: PropTypes.object,
  /**
   * The secondary action of the plan card
   */
  secondaryAction: PropTypes.object,
  width: PropTypes.string,

  height: PropTypes.string,
  style: PropTypes.object,
  paymentCard: PropTypes.bool,
  imageSize: PropTypes.any,
  transformStyle: PropTypes.any,
  colorScheme: PropTypes.object,
  subTitle: PropTypes.string,
  bestSeller: PropTypes.bool,
};
